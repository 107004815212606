import React, { useEffect, useState } from 'react';
import styles from './ProductAndServicesPage.module.scss';
import Filter from '../ProductsPage/Filter';
import { useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import {
  GET_SHOP_PRODUCTS,
  GET_CARBON_PRODUCTS,
  GET_SHOP_PRODUCTS_ALL,
  GET_CARBON_PRODUCTS_ALL,
  GET_SHOP_SERVICES,
} from '../../../api';
import { setErrorMessage } from '../../../utils/appUtils';
import ProductsTab from './ProductsTab';
import ServicesTab from './ServicesTab';
import CreditTab from './CreditTab';
import TabSelect from '../../../components/tabs';
import { SignInUpModalWrapper } from '../../Modals/SignInUpModal/SignInUpModal';
import { KycModal } from '../../../modules/tt-modals';
import {
  useDigitalAssets,
  useDigitalAssetsBridge,
  useDigitalAssetsWallet,
} from '../../../modules/tt-digital-assets-provider';
import ComingSoon from '../../../components/ComingSoon';
import { Box } from '@mui/material';

let TABS_PRODUCT = [
  {
    name: 'Products',
    key: 'products',
    child: [],
  },
  {
    name: 'Services',
    key: 'services',
    child: [],
  },
  {
    name: 'TT Carbon Registry',
    key: 'carbon',
    child: [
      {
        name: 'Carbon credits',
        key: 'credits',
        child: [],
      },
      {
        name: 'Register',
        key: 'register',
        child: [],
      },
    ],
  },
];
const comingSoonText =
  "This section allocates the products and services of startups and experts onboarded on TransparenTerra. A unique marketplace is coming soon, and Opt-in to our email list to get updates. To create your company's online store, you should create your company profile. Create your Company Profile.";
const ProductAndServicesPage = ({ companyId, isSeller, user, pageType, isProduction }) => {
  const mapApiKey = process.env.REACT_APP_GOOGLE_MAP_KEY;
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(0);
  const [activeChildTab, setActiveChildTab] = useState(0);
  const [activeMode, setActiveMode] = useState('gridMode');
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [productsList, setProductsList] = useState([]);
  const [creditList, setCreditList] = useState([]);
  const [servicesList, setServicesList] = useState([]);
  const [filterData, setFilterData] = useState({});
  const [tabs, setTabs] = useState(TABS_PRODUCT);
  const [currentPage, setCurrentPage] = useState(
    JSON.parse(localStorage.getItem('currentPage')) || 1
  );
  const [pageCount, setPageCount] = useState(0);
  const [firstRender, setFirstRender] = useState(false);
  const [disableTabs, setDisableTabs] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isSilenceUpdate, setIsSilenceUpdate] = useState(false);
  const [showNeedKYCModal, setShowNeedKYCModal] = useState(false);
  const [searchListProducts, setSearchListProducts] = useState();
  const [getShopServices] = useLazyQuery(GET_SHOP_SERVICES);
  const [getShopProducts] = useLazyQuery(GET_SHOP_PRODUCTS);
  const [getShopProductsAll] = useLazyQuery(GET_SHOP_PRODUCTS_ALL);
  const [getCarbonProducts] = useLazyQuery(GET_CARBON_PRODUCTS);
  const [getCarbonProductsAll] = useLazyQuery(GET_CARBON_PRODUCTS_ALL);
  const [anchorLoginEl, setAnchorLoginEl] = useState(null);
  const open = Boolean(anchorLoginEl);
  const location = useLocation();

  const { chainSettings, chainId } = useDigitalAssets();
  const { getBalance } = useDigitalAssetsWallet();

  useEffect(() => {
    if (searchParams.has('activeTab')) {
      const activeTabFromUrl = searchParams.get('activeTab');
      let activeTabIndex = tabs.findIndex((item) => item.key === activeTabFromUrl);
      if (activeTabIndex !== -1) {
        changeActiveTab(activeTabIndex);
      }
    }
  }, [activeTab]);

  const { bridge, getWrapBalance } = useDigitalAssetsBridge();

  useEffect(() => {
    (async () => {
      if (chainId !== chainSettings?.chainId && bridge) {
        getWrapBalance();
      }
      getBalance();
    })();
  }, [chainId, bridge]);

  const handleLoginClose = () => {
    setAnchorLoginEl(null);
  };
  const handleLoginClick = (event) => {
    event.preventDefault();
    setAnchorLoginEl(event.currentTarget);

    event.stopPropagation();
  };
  useEffect(() => {
    if (pageType !== 'marketplace' && user && isSeller) {
      let updatedTabs = TABS_PRODUCT.map((item) => {
        if (item.name === 'Products') {
          return {
            ...item,
            child: [
              {
                name: 'Active',
                key: 'active',
                child: [],
              },
              {
                name: 'Hidden',
                key: 'hidden',
                child: [],
              },
              {
                name: 'Drafts',
                key: 'drafts',
                child: [],
              },
            ],
          };
        } else {
          return item;
        }
      });
      setTabs(updatedTabs);
    }
  }, [isSeller]);

  useEffect(() => {
    if (searchParams.has('activeTab')) {
      const activeTabFromUrl = searchParams.get('activeTab');
      let activeTabIndex = tabs.findIndex((item) => item.key === activeTabFromUrl);
      if (activeTabIndex !== -1) {
        setActiveTab(activeTabIndex);
      }
    }
  }, []);

  useEffect(() => {
    if (firstRender) {
      (async () => {
        if (JSON.parse(localStorage.getItem('currentPage'))) {
          setCurrentPage(JSON.parse(localStorage.getItem('currentPage')));
        } else {
          setCurrentPage(1);
        }
        setProductsList([]);
        setCreditList([]);
        setServicesList([]);
        setPageCount(0);
        setFilterData({});
        await getProductsList({}, currentPage, false);
      })();
    }
  }, [activeChildTab]);

  useEffect(() => {
    if (filterData) {
      (async () => {
        await getProductsList(filterData, currentPage);
      })();
    } else if (firstRender) {
      (async () => {
        setFilterData({});
        await getProductsList({}, currentPage);
      })();
    }
  }, [currentPage]);

  useEffect(() => {
    if (
      location &&
      location.state &&
      location.state.action &&
      location.state.action.isOpenNotification
    ) {
      location.state.action.openNotification();
      navigate(location.pathname, {});
    }
  }, [user, location]);

  const getProducts = async (formData, page, activeTabValue, onEnter) => {
    setDisableTabs(true);
    if (pageType === 'store') {
      formData.company_id = companyId;
    }
    if (
      isSeller ||
      (user && user.roles?.length > 0 && user.roles.some((obj) => obj.name === 'admin'))
    ) {
      if (isSeller && tabs[activeTabValue].child.length) {
        formData.selected_tab = tabs[activeTabValue].child[activeChildTab]?.name.toLowerCase();
      } else {
        formData.selected_tab = 'active';
      }
      const res = await getShopProductsAll({
        variables: {
          input: formData,
          first: 15,
          page: page,
        },
        fetchPolicy: 'network-only',
      });
      if (res.data && res.data.getAllShopProductsByWithPagination) {
        if (formData.partial_search && !onEnter) {
          setSearchListProducts(res.data.getAllShopProductsByWithPagination.data);
        } else {
          setProductsList(res.data.getAllShopProductsByWithPagination.data);
          setPageCount(res.data.getAllShopProductsByWithPagination.paginatorInfo.lastPage);
        }
      } else {
        if (res.errors && res.errors[0].extensions.category === 'validation') {
          setErrorMessage('The search term must be at least 2 characters.');
        } else {
          setErrorMessage('Error while fetching products, check permissions and try again later.');
        }
      }
    } else {
      const res = await getShopProducts({
        variables: {
          input: formData,
          first: 15,
          page: page,
        },
        fetchPolicy: 'network-only',
      });
      if (res.data && res.data.getShopProductsByWithPagination) {
        if (formData.partial_search) {
          setSearchListProducts(res.data.getShopProductsByWithPagination.data);
        } else {
          setProductsList(res.data.getShopProductsByWithPagination.data);
          setPageCount(res.data.getShopProductsByWithPagination.paginatorInfo.lastPage);
        }
      } else {
        if (res.errors && res.errors[0].extensions.category === 'validation') {
          setErrorMessage('The search term must be at least 2 characters.');
        } else {
          setErrorMessage('Error while fetching products, check permissions and try again later.');
        }
      }
    }
    setDisableTabs(false);
  };

  const getServices = async (formData, page, activeTabValue, onEnter) => {
    setDisableTabs(true);
    if (pageType === 'store') {
      formData.company_id = companyId;
    }
    const res = await getShopServices({
      variables: {
        input: formData,
        first: 15,
        page: page,
      },
      fetchPolicy: 'network-only',
    });
    if (res.data && res.data.getShopServices) {
      if (formData.partial_search && !onEnter) {
        setSearchListProducts(res.data.getShopServices.data);
      } else {
        setServicesList(res.data.getShopServices.data);
        setPageCount(res.data.getShopServices.paginatorInfo.lastPage);
      }
    } else {
      if (res.errors && res.errors[0].extensions.category === 'validation') {
        setErrorMessage('The search term must be at least 2 characters.');
      } else {
        setErrorMessage('Error while fetching products, check permissions and try again later.');
      }
    }
    setDisableTabs(false);
  };

  const getCarbonCredits = async (formData, page, onEnter) => {
    setDisableTabs(true);
    if (formData.partial_search) {
      formData.partial_name = formData.partial_search;
      delete formData['partial_search'];
    }
    if (pageType === 'store') {
      formData.company_id = companyId;
    }
    if (
      isSeller ||
      (user && user.roles?.length > 0 && user.roles.some((obj) => obj.name === 'admin'))
    ) {
      const res = await getCarbonProductsAll({
        variables: {
          input: formData,
          first: 15,
          page: page,
        },
        fetchPolicy: 'network-only',
      });
      if (res.data && res.data.getAllCarbonCreditsByWithPagination) {
        if (formData.partial_name && !onEnter) {
          setSearchListProducts(res.data.getAllCarbonCreditsByWithPagination.data);
        } else {
          setCreditList(res.data.getAllCarbonCreditsByWithPagination.data);
          setPageCount(res.data.getAllCarbonCreditsByWithPagination.paginatorInfo.lastPage);
        }
      } else {
        if (res.errors && res.errors[0].extensions.category === 'validation') {
          setErrorMessage('The search term must be at least 2 characters.');
        } else {
          setErrorMessage('Error while fetching products, check permissions and try again later.');
        }
      }
    } else {
      const res = await getCarbonProducts({
        variables: {
          input: formData,
          first: 15,
          page: page,
        },
        fetchPolicy: 'network-only',
      });
      if (res.data && res.data.getCarbonCreditsByWithPagination) {
        if (formData.partial_name) {
          setSearchListProducts(res.data.getCarbonCreditsByWithPagination.data);
        } else {
          setCreditList(res.data.getCarbonCreditsByWithPagination.data);
          setPageCount(res.data.getCarbonCreditsByWithPagination.paginatorInfo.lastPage);
        }
      } else {
        if (res.errors && res.errors[0].extensions.category === 'validation') {
          setErrorMessage('The search term must be at least 2 characters.');
        } else {
          setErrorMessage('Error while fetching products, check permissions and try again later.');
        }
      }
    }
    setDisableTabs(false);
  };

  const getProductsList = async (
    data,
    page,
    isCredit = false,
    loading = true,
    activeTabValue = activeTab,
    onEnter
  ) => {
    setFirstRender(true);
    loading && !data.partial_search && setIsLoading(true);
    let formData = { ...data };

    if (
      activeTabValue === 0 &&
      (!searchParams.get('activeTab') || searchParams.get('activeTab') === 'products') &&
      !isProduction
    ) {
      await getProducts(formData, page, activeTabValue, onEnter);
    }

    if (activeTabValue === 1 && searchParams.get('activeTab') === 'services' && !isProduction) {
      await getServices(formData, page, activeTabValue, onEnter);
    }

    if (
      (activeTabValue === 2 || isCredit) &&
      activeChildTab === 0 &&
      searchParams.get('activeTab') === 'carbon'
    ) {
      await getCarbonCredits(formData, page, onEnter);
    }

    loading && !data.partial_search && setIsLoading(false);
  };

  const changeFilter = async (data, withClose, page, onEnter) => {
    const filter = { ...data };
    let queryData = { ...data };
    if (page) {
      setCurrentPage(page);
      localStorage.setItem('currentPage', JSON.stringify(page));
    } else if (JSON.parse(localStorage.getItem('currentPage'))) {
      setCurrentPage(JSON.parse(localStorage.getItem('currentPage')));
    } else {
      setCurrentPage(1);
    }

    if (withClose) {
      setOpenFilterModal(false);
    }

    if (queryData.ordersBy) {
      queryData.ordersBy = queryData.ordersBy[0].column;
    }

    let searchParamsData = queryData;
    const activeTabFromUrl = searchParams.get('activeTab');
    if (activeTabFromUrl) {
      searchParamsData = { ...queryData, activeTab: activeTabFromUrl };
    }

    setSearchParams(searchParamsData);
    await getProductsList(
      filter,
      currentPage,
      activeTabFromUrl === 'carbon',
      false,
      activeTab,
      onEnter
    );
  };

  const openProduct = (productId, userId) => {
    let url = '';
    switch (activeTab) {
      case 0:
        if (userId === user.id) {
          url = `/dashboard/product/${productId}/preview?from=${pageType}`;
        } else {
          url = `/dashboard/product/${productId}?from=${pageType}`;
        }
        break;
      case 1:
        if (userId === user.id) {
          url = `/dashboard/service/${productId}/preview?from=${pageType}`;
        } else {
          url = `/dashboard/service/${productId}?from=${pageType}`;
        }
        break;
      case 2:
        if (userId === user.id) {
          url = `/dashboard/credit/${productId}/preview?from=${pageType}`;
        } else {
          url = `/dashboard/credit/${productId}?from=${pageType}`;
        }
        break;
      default:
        url = '';
    }
    if (url) {
      localStorage.setItem('currentPage', JSON.stringify(currentPage));
      navigate(url);
    }
  };

  const editProduct = (e, id, itemCompanyId) => {
    e.stopPropagation();
    if (activeTab === 0) {
      navigate(`/dashboard/proposals/product/${id}?company=${itemCompanyId}&from=marketplace`);
    }

    if (activeTab === 1) {
      navigate(`/dashboard/proposals/service/${id}?company=${itemCompanyId}&from=marketplace`);
    }

    if (activeTab === 2) {
      navigate(`/dashboard/proposals/credit/edit/${id}?company=${itemCompanyId}&from=marketplace`);
    }
  };

  const onUpdate = () => {
    setIsUpdate(true);
  };

  const onSilenceUpdate = () => {
    setIsSilenceUpdate(true);
  };

  const onUpdateHandler = () => {
    const activeTabFromUrl = +searchParams.get('activeTab');
    getProductsList(filterData, currentPage, activeTabFromUrl === 2, true);
    setIsUpdate(false);
  };

  const onSilenceUpdateHandler = () => {
    const activeTabFromUrl = +searchParams.get('activeTab');

    getProductsList(filterData, currentPage, activeTabFromUrl === 2, false);
    setIsSilenceUpdate(false);
  };

  const changeActiveTab = async (val) => {
    setActiveTab(val);
    setSearchParams({ activeTab: tabs[val].key });
    setProductsList([]);
    setCreditList([]);
    setPageCount(0);
    await getProductsList({}, currentPage, false, true, val);
  };

  // const walletConnect = () => {
  //   if (user?.kyc === 'success') {
  //     connect();
  //   } else {
  //     setShowNeedKYCModal(true);
  //   }
  // };

  const setMode = (mode) => {
    localStorage.setItem('productMode', JSON.stringify(mode));
    setActiveMode(mode);
  };

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('productMode'))) {
      setActiveMode(JSON.parse(localStorage.getItem('productMode')));
    }
  }, []);

  useEffect(() => {
    if (isUpdate) {
      setTimeout(() => onUpdateHandler(), 50);
    }
  }, [isUpdate]);

  useEffect(() => {
    if (isSilenceUpdate) {
      setTimeout(() => onSilenceUpdateHandler(), 50);
    }
  }, [isSilenceUpdate]);

  return (
    <div className={styles.pageContentBox}>
      <div>
        {tabs && tabs.length && (
          <TabSelect
            disableTabs={disableTabs}
            setActiveTab={(e) => {
              setCurrentPage(1);
              localStorage.setItem('currentPage', JSON.stringify(1));
              changeActiveTab(e);
            }}
            activeTab={activeTab}
            setActiveChildTab={setActiveChildTab}
            activeChildTab={activeChildTab}
            options={tabs}
          />
        )}

        {activeTab === 2 && activeChildTab === 1 ? null : !isProduction ? (
          <div className={styles.productContent}>
            <Filter
              openFilterModal={openFilterModal}
              setOpenFilterModal={setOpenFilterModal}
              changeFilter={changeFilter}
              setFilterData={setFilterData}
              setActiveMode={setMode}
              user={user}
              openProduct={openProduct}
              activeMode={activeMode}
              activeTab={activeTab}
              pageType={pageType}
              isCredit={activeTab === 2}
              searchListProducts={searchListProducts}
              setSearchListProducts={setSearchListProducts}
              onUpdate={onUpdate}
              setCurrentPage={setCurrentPage}
            />
          </div>
        ) : null}
      </div>
      {isProduction && (activeTab === 0 || activeTab === 1) && (
        <Box sx={{ marginTop: '16px' }}>
          <ComingSoon text={comingSoonText} />
        </Box>
      )}
      {activeTab === 0 && !isProduction && (
        <ProductsTab
          pageType={pageType}
          handleLoginClick={handleLoginClick}
          isLoading={isLoading}
          activeMode={activeMode}
          productsList={productsList}
          openProduct={openProduct}
          pageCount={pageCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          mapApiKey={mapApiKey}
          user={user}
          editProduct={editProduct}
          onUpdate={onUpdate}
          onSilenceUpdate={onSilenceUpdate}
        />
      )}
      {activeTab === 1 && !isProduction && (
        <ServicesTab
          pageType={pageType}
          handleLoginClick={handleLoginClick}
          isLoading={isLoading}
          activeMode={activeMode}
          productsList={servicesList}
          openProduct={openProduct}
          pageCount={pageCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          mapApiKey={mapApiKey}
          user={user}
          editProduct={editProduct}
          onUpdate={onUpdate}
        />
      )}
      {activeTab === 2 && (
        <CreditTab
          user={user}
          activeMode={activeMode}
          activeChildTab={activeChildTab}
          isLoading={isLoading}
          productsList={creditList}
          openProduct={openProduct}
          pageCount={pageCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageType={pageType}
          editProduct={editProduct}
          handleLoginClick={handleLoginClick}
        />
      )}
      <KycModal isOpen={showNeedKYCModal} setIsOpen={setShowNeedKYCModal} userId={user?.id} />
      <SignInUpModalWrapper open={open} closeModal={handleLoginClose} />
    </div>
  );
};

export default ProductAndServicesPage;
