import { useNavigate } from 'react-router';
import React, { useContext, useState } from 'react';
import styles from './registrationType.module.scss';
import HelpModal from './HelpModal';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import { ButtonBase } from '@mui/material';
import { HelpIcon } from 'tt-ui-kit';
import userContext from '../../../context/User/userContext';
import { KycModal, UpgradeRoleModal } from '../../../modules/tt-modals';
import { Modal } from 'tt-ui-kit';
const radioSx = {
  '&, &.Mui-checked': {
    color: '#212121',
  },
};

const RegistrationTypePage = () => {
  const navigate = useNavigate();

  const { user } = useContext(userContext);
  const [selectedType, setSelectedType] = useState('representative');
  const [isKycModelOpen, setIsKycModalOpen] = useState(false);
  const [helpModal, setHelpModal] = useState(false);
  const [isUpgradeRoleModalOpen, setIsUpgradeRoleModalOpen] = useState(false);

  const isUserIndividual = user.role_name === 'individual' || user.role_name === 'digital';
  const isUserExpert = user.role_name === 'expert';
  const isUserHasKYC = user.kyc === 'success';

  const handleClick = () => {
    if (isUserIndividual) {
      setIsUpgradeRoleModalOpen(true);
      return;
    }

    if (!isUserHasKYC) {
      setIsKycModalOpen(true);
      return;
    }

    if (selectedType === 'representative') {
      navigate('/dashboard/companyProfile/representative');
    } else {
      navigate('/dashboard/companyProfile/externalAssessment');
    }
  };

  return (
    <>
      <div className={styles.content}>
        <div className={styles.title}>Create Your Company Account</div>
        <div className={styles.typesArea}>
          <FormControl>
            <RadioGroup
              defaultValue="representative"
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={(e) => {
                if (isUserExpert || e.target.value === 'representative') {
                  setSelectedType(e.target.value);
                } else {
                  setIsUpgradeRoleModalOpen(true);
                }
              }}
            >
              <FormControlLabel
                className={styles.type}
                value="representative"
                control={<Radio sx={radioSx} />}
                label={<Typography className={styles.type}>Company representative</Typography>}
              />
              <FormControlLabel
                disabled={!isUserExpert}
                onClick={!isUserExpert ? () => setIsUpgradeRoleModalOpen(true) : undefined}
                className={styles.type}
                value="external_assessment"
                control={<Radio sx={radioSx} />}
                label={<Typography className={styles.type}>For external assessment</Typography>}
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div className={styles.typesExplanationContent}>
          <p>Create a company profile by selecting the relevant section that meets your needs.</p>
          <p>There are two options:</p>
          <Box className={styles.optionsList}>
            <Box className={styles.listItem}>
              <Box component="span" className={styles.typesExplanationContentTypeName}>
                Business profile.&nbsp;
              </Box>
              Creating detailed business profile as a company owner or representative gives you
              access to the valuable &quot;Open for Investments&quot; feature.
              <Box component="span" className={styles.helpIcon} onClick={() => setHelpModal(true)}>
                <HelpIcon />
              </Box>
            </Box>
            <Box className={styles.listItem}>
              <Box component="span" className={styles.typesExplanationContentTypeName}>
                Assessment profile.&nbsp;
              </Box>
              Creating an assessment profile is needed to provide third-party expertise. This
              profile will be available for you or your customers as a part of your internal
              registry and workflow.
            </Box>
          </Box>
        </div>
      </div>
      <div className={styles.button}>
        <ButtonBase onClick={handleClick} className={styles.rightButton}>
          PROCEED
        </ButtonBase>
      </div>
      <KycModal
        isOpen={isKycModelOpen}
        setIsOpen={setIsKycModalOpen}
        userId={user.id}
        userKyc={user.kyc}
      />
      <HelpModal open={helpModal} handleClose={() => setHelpModal(false)} />
      <UpgradeRoleModal
        open={isUpgradeRoleModalOpen}
        setOpen={setIsUpgradeRoleModalOpen}
        linkTooltipText="Coming soon..."
      />
    </>
  );
};

export default RegistrationTypePage;
