import Cookies from 'js-cookie';
import { openNotification } from 'tt-ui-lib/core';

export const openAppLoader = () => {
  const loader = document.getElementById('loadingsScreen');
  if (loader) {
    loader.style.opacity = 1;
    loader.style.visibility = 'visible';
  }
};

export const closeAppLoader = () => {
  const loader = document.getElementById('loadingsScreen');
  if (loader) {
    loader.style.opacity = 0;
    loader.style.visibility = 'hidden';
  }
};

export const logoutUserHandler = (logoutApiMethod, withRedirect = true) => {
  openAppLoader();

  logoutApiMethod()
    .then((response) => {
      if (response.data.logout) {
        const domainValue = process.env.REACT_APP_DOMAIN;
        Cookies.remove('access_token', { domain: domainValue });
        Cookies.remove('userId', { domain: domainValue });
        Cookies.remove('marketId', { domain: domainValue });
        Cookies.remove('calculatorId', { domain: domainValue });
        Cookies.remove('companyId', { domain: domainValue });
        Cookies.remove('last_active', {
          domain: domainValue,
        });
        localStorage.removeItem('currentCompanyData');

        if (withRedirect) {
          window.location.href = '/dashboard/marketplace';
        } else {
          window.location.reload();
        }
      } else {
        closeAppLoader();
      }
    })
    .catch(() => {
      closeAppLoader();
      const domainValue = process.env.REACT_APP_DOMAIN;
      Cookies.remove('access_token', { domain: domainValue });
      Cookies.remove('marketId', { domain: domainValue });
      Cookies.remove('userId', { domain: domainValue });
      Cookies.remove('calculatorId', { domain: domainValue });
      Cookies.remove('companyId', { domain: domainValue });
      Cookies.remove('last_active', {
        domain: domainValue,
      });
      localStorage.removeItem('currentCompanyData');

      if (withRedirect) {
        window.location.href = '/dashboard/marketplace';
      } else {
        window.location.reload();
      }
    });
};

export const setErrorMessage = (text) => {
  openNotification({
    message: text,
    type: 'error',
  });
};

export const downloadFileFromURL = (url, name) => {
  const link = document.createElement('a');
  link.download = name;
  link.href = url;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const removeUrlParameter = (url, paramToRemove) => {
  let splitUrl = url.split('?');
  let newUrl = splitUrl[0];
  let params = splitUrl[1].split('&');

  params = params.filter((param) => param.split('=')[0] !== paramToRemove);

  let newParams = params.join('&');

  if (newParams) {
    newUrl += '?' + newParams;
  }

  return newUrl;
};

export const removeParamFromUrl = (paramKeys = [], withReload = false) => {
  let url = window.location.href;

  paramKeys.forEach((paramKey) => {
    url = removeUrlParameter(url, paramKey);
  });

  window.history.replaceState(null, '', url);
  if (withReload) {
    window.location.reload();
  }
};

export const redirectToLink = (url, target = '_self') => {
  const link = document.createElement('a');

  link.href = url;
  link.target = target;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const groupListByKey = (list, key = '') => {
  if (!key) {
    return list;
  }

  if (list.length === 0) {
    return [];
  }

  const grouped = {};

  list.forEach((obj) => {
    const keyItem = obj[key];

    if (!grouped[keyItem]) {
      grouped[keyItem] = [];
    }

    grouped[keyItem].push(obj);
  });

  const result = [];

  for (const keyValue in grouped) {
    if (grouped.hasOwnProperty(keyValue)) {
      result.push({ [key]: keyValue, items: grouped[keyValue] });
    }
  }

  return result;
};

export const getAvatarPathBySize = (files, pathSize) => {
  if (files && files.length) {
    return files[0][pathSize];
  }
  return '';
};

export const redirectToMessengerWithUser = (id, inNewTab = true) => {
  if (!id) return;

  redirectToLink(
    `${process.env.REACT_APP_TT}/dashboard/messaging?profile=${id}`,
    inNewTab ? '_blank' : '_self'
  );
};
