import styles from './account.module.scss';
import { Controller } from 'react-hook-form';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import LocationModal from '../locationModal';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import SelectComponent from './accountLogo/Select';
import { GET_COUNTRIES } from '../../api';
import { useLazyQuery } from '@apollo/client';

const radioSx = {
  '&, &.Mui-checked': {
    color: '#212121',
  },
};

const validationTexts = {
  required: 'Please fill out the rest fields. This is mandatory',
  invalidWebsite: 'Invalid Website URL',
};

export const Account = ({
  register,
  control,
  setValue,
  errors,
  setError,
  companyInfo,
  getValues,
  showGreen = false,
  openForDonation = false,
}) => {
  const [isLocationModal, setIsLocationModal] = useState(false);
  const [coordinates, setCoordinates] = useState();
  const [countriesList, setCountriesList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [getCountriesList] = useLazyQuery(GET_COUNTRIES);

  const handleApplyLocation = (value) => {
    setValue('address', value.address);
    setError('address', null);
    if (value && value.locationData) {
      const locationData = value.locationData;
      let country = '';
      if (locationData.country_code) {
        const countryItem = countriesList.find(
          (item) =>
            item.iso_3166_2 === locationData.country_code ||
            item.iso_3166_3 === locationData.country_code
        );
        country = countryItem?.name || '';
      }
      setSelectedCountry(country);
      setValue('location', {
        address: value.address,
        data: {
          latLng: {
            lat: value.coordinates?.lat.toString(),
            lng: value.coordinates?.lng.toString(),
          },
          country: country,
          locality: locationData.locality || '',
          postcode: locationData.postcode || '',
          country_code: locationData.country_code || '',
        },
      });
    }
  };

  useEffect(() => {
    if (companyInfo && companyInfo.is_governmental) {
      setValue('governmentalOrganization', companyInfo.is_governmental);
    }
    if (companyInfo && companyInfo.hasOwnProperty('for_profit')) {
      setValue('for_profit', companyInfo.for_profit ? 1 : 0);
    }
    if (companyInfo && companyInfo.website) {
      setValue('website', companyInfo.website);
    }
    if (companyInfo && companyInfo.address2) {
      setValue('address2', companyInfo.address2);
    }
    if (companyInfo && companyInfo.addressLocation && companyInfo.addressLocation.address) {
      setValue('address', companyInfo.addressLocation.address);
      setValue('location.data.postcode', companyInfo.addressLocation.postcode);
      setValue('location.data.country', companyInfo.addressLocation.country);
      setValue('location.data.locality', companyInfo.addressLocation.locality);

      setCoordinates({
        lng: parseFloat(companyInfo.addressLocation.lng),
        lat: parseFloat(companyInfo.addressLocation.lat),
      });

      setValue('location', {
        address: companyInfo.addressLocation.address,
        data: {
          latLng: {
            lat: companyInfo.addressLocation.lat.toString(),
            lng: companyInfo.addressLocation.lng.toString(),
          },
          country: companyInfo.addressLocation.country || '',
          postcode: companyInfo.addressLocation.postcode || '',
          locality: companyInfo.addressLocation.locality || '',
        },
      });
    }
  }, [companyInfo]);

  const getCountries = async () => {
    const res = await getCountriesList({
      fetchPolicy: 'network-only',
    });
    if (res.data && res.data.showCountries) {
      setCountriesList(res.data.showCountries);
    }
  };

  useEffect(() => {
    (async () => {
      await getCountries();
    })();
  }, []);

  return (
    <>
      <div>
        <h3 className={styles.radioGroupTitle}>Please choose below your organization type</h3>
        <div className={styles.radioGroup}>
          <Controller
            name={'for_profit'}
            control={control}
            defaultValue={1}
            render={({ field: { onChange, value } }) => (
              <>
                <RadioGroup value={value == 1 ? 1 : 0} onChange={onChange} row>
                  <FormControlLabel
                    value={1}
                    control={<Radio sx={radioSx} defaultChecked />}
                    label="For profit"
                  />
                  <FormControlLabel
                    value={0}
                    control={<Radio sx={radioSx} />}
                    label="Non for profit"
                  />
                </RadioGroup>
              </>
            )}
          />
          <Controller
            name="governmentalOrganization"
            control={control}
            render={({ field: { onChange, value } }) => (
              <>
                <FormGroup onChange={onChange}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checkedIcon={<CheckBoxOutlinedIcon style={{ color: '#212121' }} />}
                        key={value}
                        defaultChecked={value ? 1 : 0}
                      />
                    }
                    label="Governmental organization"
                  />
                </FormGroup>
              </>
            )}
          />
        </div>
        <div className={styles.center}>
          <TextField
            {...register('address', { required: validationTexts.required })}
            className={styles.modal_input}
            style={{ width: '100%' }}
            placeholder="Location*"
            onClick={() => setIsLocationModal(true)}
            data-type="address"
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: errors?.address?.message ? 'red' : '#EFEFEF',
              },
            }}
            InputProps={{
              style: { cursor: 'pointer' },
              readOnly: true,
              endAdornment: (
                <InputAdornment position="start">
                  <img src="/images/map-icon.svg" />
                </InputAdornment>
              ),
            }}
          />
          {errors?.address?.message && (
            <span className={styles.errorsText}>
              Please fill out the rest fields. This is mandatory.
            </span>
          )}
        </div>
        <div className={styles.accountCenterRow}>
          <div className={styles.accountColumn}>
            <div className={styles.accountInputContainer} style={{ width: '100%' }}>
              <TextField
                key={getValues('location.data.postcode')}
                className={styles.input}
                {...register('location.data.postcode', {
                  required: 'Please fill out the rest fields. This is mandatory.',
                  pattern: {
                    value: /^.{3,9}$/,
                    message: 'Please enter a valid postal code.',
                  },
                })}
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: errors?.location?.data?.postcode ? 'red' : '#EFEFEF',
                  },
                }}
                type="text"
                label="Zip/Postal code*"
              />
              {errors?.location?.data?.postcode && (
                <span className={styles.errorsText}>{errors.location.data.postcode.message}</span>
              )}
            </div>
            <div className={styles.accountInputContainer} style={{ width: '100%' }}>
              <SelectComponent
                key={`country-${selectedCountry}`}
                values={countriesList}
                valueField="name"
                labelField="name"
                name="location.data.country"
                placeholder="Registration country*"
                register={register}
                getValues={getValues}
                errors={errors}
                changeValue={(value) => {
                  setSelectedCountry(value);
                  setValue(
                    'location.data.country_code',
                    countriesList.find((item) => item.name === value)?.iso_3166_2 || ''
                  );
                }}
              />
              {errors?.location?.data?.country && (
                <span className={styles.errorsText}>
                  Please fill out the rest fields. This is mandatory.
                </span>
              )}
            </div>
          </div>
          <div className={styles.accountColumn}>
            <div className={styles.accountInputContainer} style={{ width: '100%' }}>
              <TextField
                key={getValues('location.data.locality')}
                className={styles.input}
                {...register('location.data.locality', { required: validationTexts.required })}
                type="text"
                label="Town/City*"
              />
              {errors?.location?.data?.locality && (
                <span className={styles.errorsText}>{errors?.locality?.message}</span>
              )}
            </div>
            <div className={styles.accountInputContainer} style={{ width: '100%' }}>
              <TextField
                className={styles.input}
                {...register('address2')}
                type="text"
                label="Address 2"
              />
            </div>
          </div>
          {showGreen && (
            <div className={styles.greenBlock}>
              <div className={styles.accountInputContainer} style={{ width: '100%' }}>
                <TextField
                  className={styles.input}
                  type="text"
                  label="Website"
                  {...register('website', {
                    pattern: {
                      value:
                        /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
                      message: validationTexts.invalidWebsite,
                    },
                  })}
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: errors?.website ? 'red' : '#EFEFEF',
                    },
                  }}
                />
                {errors?.website && (
                  <span className={styles.errorsText}>{errors?.website?.message}</span>
                )}
              </div>
              <div className={styles.greenContent}>
                <img
                  src="/images/greenAccount.png"
                  style={{
                    height: '48px',
                    width: '114px',
                  }}
                />
                <div className={styles.greenContentText}>
                  <p>
                    Is your website hosted green? Check it with{' '}
                    <a
                      target="_blank"
                      href="https://www.thegreenwebfoundation.org"
                      className={styles.underline}
                      rel="noopener noreferrer"
                    >
                      The Green Web Foundation.{' '}
                    </a>{' '}
                    It&apos;s free
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <LocationModal
        title="Choose your location"
        isModelOpen={isLocationModal}
        handleClose={() => setIsLocationModal(false)}
        coordinatesValue={coordinates}
        handleApply={handleApplyLocation}
      />
    </>
  );
};
