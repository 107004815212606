import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { AppAvatar, Image, Menu, MenuItem, RightIcon } from 'tt-ui-kit';
import { Link } from 'react-router-dom';

import styles from './NavBarNotificationButton.module.scss';

const NavBarNotificationButton = ({
  Icon,
  text,
  user,
  notifications,
  getUserNotifications,
  onClick,
  setShowNeedKYCModal,
  setIsAgreementModalOpen,
  logOut,
  redirectDomain,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (anchorEl) {
      getUserNotifications();
    }
  }, [anchorEl]);

  return (
    <div style={{ height: '100%' }}>
      <button
        onClick={onClick || handleClick}
        type="button"
        className={open ? styles.navbarLinkActive : styles.navbarLink}
      >
        <Icon className={clsx(styles.linkIcon, open ? styles.linkIconActive : '')} />
        <span className={styles.navbarLinktext}>{text}</span>
      </button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{
          '.MuiList-root': {
            paddingBottom: '20px',
            paddingTop: '9px',
          },
          '.MuiPaper-root': {
            boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.13)',
            borderRadius: '12px',
          },
        }}
      >
        {notifications.length ? (
          notifications.map(
            (item, index) =>
              index < 5 &&
              !item.read_at && (
                <MenuItem key={item.id} className={styles.notification}>
                  <div>
                    {item.user ? (
                      <AppAvatar
                        user={user}
                        nav={0}
                        userName={`${item.user.first_name} ${item.user.last_name}`}
                        src={item.user.avatar}
                        size="xs"
                        setShowNeedKYCModal={setShowNeedKYCModal}
                        setIsAgreementModalOpen={setIsAgreementModalOpen}
                        logOut={logOut}
                      />
                    ) : null}
                  </div>
                  <div className={styles.notificationInfo}>
                    <div className={styles.notificationTitle}>{item.message}</div>
                    <div className={styles.notificationTitle}>{item?.content?.title}</div>
                    <div
                      className={styles.notificationDescription}
                      dangerouslySetInnerHTML={{ __html: `${item?.content?.body}` }}
                    />
                  </div>
                </MenuItem>
              )
          )
        ) : (
          <MenuItem className={styles.notification} disabled>
            You have not notifications
          </MenuItem>
        )}
        <div className={styles.viewAllLinkBlock}>
          <Link
            to={
              redirectDomain
                ? `${redirectDomain}/dashboard/notification`
                : '/dashboard/notification'
            }
            onClick={() => setAnchorEl(false)}
            underline="none"
            className={styles.viewAllLink}
          >
            <span className={styles.viewAllLinkText}>View all</span>
            <RightIcon style={{ width: 20, height: 20 }} />
          </Link>
        </div>
      </Menu>
    </div>
  );
};

export default NavBarNotificationButton;
