import React from 'react';
import UserState from 'context/User/UserState';
import { AppState } from 'context';

const MainContextProvider = ({ children }) => (
  <AppState>
    <UserState>{children}</UserState>
  </AppState>
);

export default MainContextProvider;
