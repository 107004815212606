import React from 'react';

import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { Checkbox, FormControlLabel } from '@mui/material';
import styles from './paymentForm.module.scss';
import ModalAdd from '../../../../modalAdd';
import { useFormContext, Controller } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import StripeIcon from '../../../../icons/StripeIcon';
import { useLazyQuery } from '@apollo/client';
import { GET_PAYMENT_ACCOUNTS_BY_COMPANY } from '../../../../../api';

const checkSx = {
  fontFamily: 'Montserrat',
  fontWeight: '500',
  fontSize: '16px',
  lineHeight: '24px',
  color: '#212121',
};

//TODO 2. Create page with buttons(or links) to register/login seller in Payment System
export const PaymentForm = ({ companyId, basePage }) => {
  const [stripeAccount, setStripeAccount] = useState();
  const [getPaymentAccounts, { data: payment, refetch }] = useLazyQuery(
    GET_PAYMENT_ACCOUNTS_BY_COMPANY
  );
  const {
    setValue,
    control,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    setValue('payments.stripe', true);
  }, []);

  useEffect(() => {
    if (companyId && !payment) {
      getPaymentAccounts({
        variables: {
          id: companyId,
        },
      });
    }
  }, [companyId]);

  useEffect(() => {
    if (payment) {
      setStripeAccount(
        payment?.showCompanyById?.paymentAccountList?.find(
          (account) => account?.payment_system === 'stripe'
        )
      );
    }
    console.log('stripeAccount: ', stripeAccount);
  }, [payment]);

  //TODO 3. Add event listener and callback, which we will use after... START
  const onRegister = useCallback(() => {
    if (refetch && companyId) {
      refetch({ id: companyId });
    }
  }, [refetch, companyId]);

  const onMessageReceive = useCallback(
    (event) => {
      if (event.data?.callback === 'onRegister') {
        onRegister();
      }
    },
    [onRegister]
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('message', onMessageReceive, false);
    }

    return () => {
      window.removeEventListener('message', onMessageReceive);
    };
  }, [onMessageReceive]);
  // 3. Add event listener and callback, which we will use after... END

  //TODO 5. Open The Payment System Page on a new tab
  const openWindow = (pageName, id) => {
    if (typeof window !== 'undefined' && id && basePage) {
      let url = `${process.env.REACT_APP_MARKET}/${pageName}?basePage=${basePage}&id=${id}`;
      window.open(`${url}`, '_blank');
    }
  };

  return (
    <>
      <div className={styles.paymentDiv}>
        <div className={styles.paymentText}>Accepting payment methods</div>
        <div className={styles.paymentRow}>
          <div className={styles.paymentColumnLeft}>
            <Controller
              name={'payments.stripe'}
              control={control}
              defaultValue={false}
              render={({ field: { onChange, value } }) => (
                <>
                  <FormControlLabel
                    className={styles.paymentControlLabel}
                    control={
                      <Checkbox
                        checkedIcon={<CheckBoxOutlinedIcon style={{ color: '#212121' }} />}
                        onChange={onChange}
                        checked={value}
                        sx={checkSx}
                      />
                    }
                    label={'Stripe'}
                    defaultChecked
                  />
                </>
              )}
            />
            <Controller
              name={`payments.paypal`}
              control={control}
              defaultValue={false}
              render={({ field: { onChange, value } }) => (
                <>
                  <FormControlLabel
                    className={styles.paymentControlLabel}
                    control={
                      <Checkbox
                        disabled
                        checkedIcon={<CheckBoxOutlinedIcon style={{ color: '#212121' }} />}
                        onChange={onChange}
                        checked={value}
                        sx={checkSx}
                      />
                    }
                    label="Paypal"
                  />
                </>
              )}
            />
            <Controller
              name={'payments.crypto'}
              control={control}
              defaultValue={false}
              render={({ field: { onChange, value } }) => (
                <>
                  <FormControlLabel
                    className={styles.paymentControlLabel}
                    control={
                      <Checkbox
                        checkedIcon={<CheckBoxOutlinedIcon style={{ color: '#212121' }} />}
                        disabled
                        onChange={onChange}
                        checked={value}
                        sx={checkSx}
                      />
                    }
                    label={'Crypto'}
                  />
                </>
              )}
            />
          </div>
          <div className={styles.paymentColumnRight}>
            <div className={styles.paymentButtonContainer}>
              {/*login into stripe or complete account*/}
              {stripeAccount?.completed_onboarding === true ? (
                <button
                  onClick={() => openWindow(`payment/loginAccount`, companyId)}
                  className={styles.connectedStripeButton}
                  type="button"
                >
                  <span className={styles.iconWrapper}>
                    <StripeIcon />
                  </span>
                  <span>STRIPE CONNECTED</span>
                </button>
              ) : (
                <button
                  onClick={() => openWindow(`payment/registerAccount`, companyId)}
                  className={styles.connectStripeButton}
                  type="button"
                >
                  <span className={styles.iconWrapper}>
                    <StripeIcon />
                  </span>
                  <span>CONNECT STRIPE</span>
                </button>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: '3px',
          }}
        >
          <div>
            <ModalAdd type="payment" />
          </div>
        </div>
      </div>
    </>
  );
};
