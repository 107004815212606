import { Box, Checkbox, FormControlLabel, Menu, styled, Typography } from '@mui/material';
import styles from './deliveryPage.module.scss';
import ModalAdd from '../../../../modalAdd';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useMutation } from '@apollo/client';
import LocationModal from '../../../../locationModal';
import { CREATE_ADDRESS_LOCATION } from '../../../../../api';
import Image from '../../../../Image';
import { SearchIcon } from 'tt-ui-kit';
import { Button, HelpTooltip } from 'tt-ui-kit';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { PickupItem } from './PickupItem';

const deliveryMethods = [
  {
    label: 'Pickup',
    name: 'delivery_pickup',
    disabled: false,
    helpMessage:
      'If applicable, check the box and enter details on your delivery pickup policy. Provide the address of your store, buying club drop-off location, etc Pay attention to the fact that the more flexible and diverse methods of delivery you offer your Buyers, the more attractive you will be to them as the Seller.',
  },
  // {
  //   label: 'Digital',
  //   name: 'delivery_digital',
  //   disabled: true,
  // },
  // {
  //   label: 'By post',
  //   name: 'delivery_shipping',
  //   disabled: true,
  //   helpMessage:
  //     'This way delivery can expand the geography of sales and is for the manufacturer of honey, mushrooms, herbal collections, etc.If you are ready to deliver your products, goods or services by postal, courier or transport companies to the addresses indicated by the Buyers in their applications, check the box to the left of this menu item.',
  // },
  {
    label: 'Seller Delivery',
    name: 'delivery_hand',
    disabled: false,
    helpMessage:
      'If applicable, check the box and enter details on your delivery pickup policy. Receive proof of delivery (photos, documents) when you hand over the goods to the buyer. Pay attention to the fact that the more flexible and diverse methods of delivery you offer your Buyers, the more attractive you will be to them as the Seller.',
  },
];

const defaultUnite = 'km';

const checkSx = {
  fontFamily: 'Montserrat',
  fontWeight: '500',
  fontSize: '16px',
  lineHeight: '24px',
  color: '#212121',
};

const CustomizedAccordion = {
  '& .MuiButtonBase-root': {
    '&.Mui-expanded': {
      transform: 'none',
      // -webkit-transform: 'none',
    },
    padding: '0px !important',
    margin: '0px !important',
    minHeight: '0px !important',
    '& .MuiAccordionSummary-content': {
      margin: 0,
      paddingRight: '25px',
      '& .MuiTypography-root': {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 16,
        color: '#212121',
      },
    },
  },
  '&.MuiPaper-root::before': {
    backgroundColor: 'transparent',
  },
};

export const DeliveryForm = ({
  pickupsList,
  selectedCurrency,
  unitsList,
  deliveryMethodData,
  setDeliveryMethodData,
  openNewPickupModal,
  currenciesList,
  setEditPickupData,
  setEditablePickupIndex,
  setIsEditModal,
  pickupData,
  setPickupData,
}) => {
  const {
    register,
    control,
    getValues,
    setValue,
    formState: { errors },
    clearErrors,
    watch,
  } = useFormContext();

  const { remove: pickupRemove } = useFieldArray({
    control,
    keyName: 'key',
    name: 'pickupAddresses',
  });
  const [isLocationModal, setIsLocationModal] = useState('');
  const [delivery, setDelivery] = useState({});
  const [pickupFields, setPickupFields] = useState([]);
  const [locationValue, setLocationValue] = useState({});
  const [selectLocation, setSelectLocation] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const openOptions = Boolean(anchorEl);
  const [createAddressLocation] = useMutation(CREATE_ADDRESS_LOCATION);
  const [expanded, setExpanded] = useState(0);

  useEffect(() => {
    const oldDeliveryData = getValues('delivery');
    if (deliveryMethodData) {
      setDelivery({
        delivery_digital: false,
        delivery_hand: !!deliveryMethodData.delivery_hand,
        delivery_pickup: !!deliveryMethodData.delivery_pickup,
        delivery_shipping: false,
      });
      setValue('delivery', {
        delivery_digital: false,
        delivery_hand: !!deliveryMethodData.delivery_hand,
        delivery_pickup: !!deliveryMethodData.delivery_pickup,
        delivery_shipping: false,
      });
      let pickupResponse = deliveryMethodData?.shopAddresses?.filter((item) => item.is_pickup);
      let handAddressData = {};
      deliveryMethodData?.shopAddresses?.forEach((item) => {
        if (!item.is_pickup) {
          handAddressData = item;
        }
      });
      setValue('pickupAddresses', pickupResponse);
      setValue('handAddresses', handAddressData);
      setValue('handAddresses.address', handAddressData?.addressLocation?.address);
      setValue('handAddresses.address_location_id', handAddressData?.address_location_id);
      setPickupData(pickupResponse);
    } else if (oldDeliveryData) {
      setDelivery(oldDeliveryData);
    }
    const dataDelivery = getValues('delivery');
    setValue('delivery', { ...dataDelivery, delivery_digital: false, delivery_shipping: false });
  }, []);

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'pickupAddresses') {
        setPickupFields(value.pickupAddresses);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleApplyLocation = async (value) => {
    if (isLocationModal === 'locationPickup') {
      setLocationValue(value);
      setSelectLocation(value.address);
    }
    if (isLocationModal === 'handAddresses') {
      setValue('handAddresses.address', value.address);
      const getLocationId = await createAddressLocation({
        variables: {
          input: {
            address: value.address,
            data: {
              latLng: {
                lat: value.coordinates.lat.toString(),
                lng: value.coordinates.lng.toString(),
              },
              country: value.locationData.country || '',
              country_code: value.locationData.country_code || '',
              locality: value.locationData.locality || '',
              postcode: value.locationData.postcode || '',
            },
          },
        },
      });
      let locationId = '';
      if (getLocationId.data && getLocationId.data.createAddressLocation) {
        locationId = getLocationId.data.createAddressLocation.id;
        setValue('handAddresses.address_location_id', locationId);
      }
    }
  };

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function findorderedDays(days) {
    days = days.sort((a, b) => a.id - b.id);
    const orderedDays = [];
    let startDay = days[0];
    let endDay = days[0];

    for (let i = 1; i < days.length; i++) {
      const currentDay = days[i];
      const previousDay = days[i - 1];

      if (currentDay.id === previousDay.id + 1) {
        endDay = currentDay;
      } else {
        if (startDay === endDay) {
          orderedDays.push(startDay.name);
        } else if (startDay.id + 1 !== endDay.id) {
          orderedDays.push(`${startDay.name}-${endDay.name}`);
        } else {
          orderedDays.push(startDay.name);
          orderedDays.push(endDay.name);
        }
        startDay = currentDay;
        endDay = currentDay;
      }
    }

    // Add the last consecutive days
    if (startDay === endDay) {
      orderedDays.push(startDay.name);
    } else {
      orderedDays.push(`${startDay.name}-${endDay.name}`);
    }

    return `${orderedDays.join(', ')} `;
  }

  const openCloseEpanded = (index) => {
    if (pickupFields.length > 0) {
      if (index === expanded) {
        setExpanded(null);
      } else {
        setExpanded(index);
      }
    }
  };

  const editPickupMobile = (e, pickup, index) => {
    e.stopPropagation();
    setEditPickupData(pickup);
    setEditablePickupIndex(index);
    setIsEditModal(true);
    openNewPickupModal();
    setExpanded(expanded);
    setAnchorEl(!anchorEl);
  };

  const editPickup = (pickup, index) => {
    setEditPickupData(pickup);
    setEditablePickupIndex(index);
  };

  const deletePickupMobile = (e, pickup, index) => {
    e.stopPropagation();
    pickupRemove(index);
    setExpanded(expanded);
    setAnchorEl(!anchorEl);
  };

  const StyledMenu = styled((props) => (
    <Menu
      id="pickup-options-menu"
      anchorEl={anchorEl}
      open={openOptions}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'menu-button',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      boxShadow: '0px 4px 32px rgba(0, 0, 0, 0.08)',
      borderRadius: '12px',
      '& .MuiMenu-list': {},
    },
  }));

  return (
    <>
      <div className={styles.deliveryDiv}>
        <div className={styles.titleBlock}>
          <h2>Delivery methods.</h2>
          <HelpTooltip
            tooltipText="This is public information"
            iconClassName={styles.helpIconStyle}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <div className={styles.deliveryColumnLeft}>
            {delivery.delivery_pickup && (
              <Button
                type="default"
                className={styles.addMServiceButton}
                onClick={() => {
                  openNewPickupModal();
                  setIsEditModal(false);
                }}
              >
                <Image src="/images/company/plus.svg" />
                <Typography className={styles.btnText}>Add new</Typography>
              </Button>
            )}
            {deliveryMethods.map((item) => (
              <>
                <Controller
                  name={`delivery.${item.name}`}
                  control={control}
                  defaultValue={false}
                  key={item.name}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <FormControlLabel
                        className={styles.formControl}
                        control={
                          <Checkbox
                            onChange={(e) => {
                              onChange(e);
                              if (!e.target.checked && item.name === 'delivery_pickup') {
                                setValue('pickupAddresses', []);
                              }
                              if (!e.target.checked && item.name === 'delivery_shipping') {
                                setValue('delivery.is_post_worldwide', false);
                              }
                              if (!e.target.checked && item.name === 'delivery_hand') {
                                clearErrors();
                                setValue('handAddresses', {});
                              }
                              setDelivery((current) => ({
                                ...current,
                                [item.name]: e.target.checked,
                              }));
                              setDeliveryMethodData((current) => ({
                                ...current,
                                [item.name]: e.target.checked,
                              }));
                            }}
                            checkedIcon={<CheckBoxOutlinedIcon sx={{ fill: '#212121' }} />}
                            checked={value}
                            disabled={item.disabled}
                            sx={checkSx}
                          />
                        }
                        label={
                          <Box className={styles.rowItem}>
                            <Box className={styles.checkboxItems}>
                              <Typography className={styles.checkBoxLabel}>{item.label}</Typography>
                            </Box>
                          </Box>
                        }
                      />
                      {getValues('delivery.delivery_pickup') &&
                        item.name === 'delivery_pickup' &&
                        pickupFields.length === 0 && (
                          <Box>
                            <Typography className={styles.errorPickup}>
                              At least one pickup location is required for this delivery method.
                            </Typography>
                          </Box>
                        )}
                      {delivery[item.name] &&
                        item.name === 'delivery_pickup' &&
                        !item.disabled &&
                        pickupFields.length > 0 &&
                        pickupFields.map((pickup, index) => {
                          return (
                            <PickupItem
                              index={index}
                              pickup={pickup}
                              key={index}
                              currenciesList={currenciesList}
                              expanded={expanded}
                              setExpanded={setExpanded}
                              pickupFields={pickupFields}
                              anchorEl={anchorEl}
                              setAnchorEl={setAnchorEl}
                              editPickupMobile={editPickupMobile}
                              editPickup={editPickup}
                              deletePickupMobile={deletePickupMobile}
                              openNewPickupModal={openNewPickupModal}
                              setIsEditModal={setIsEditModal}
                              pickupRemove={pickupRemove}
                              selectedCurrency={selectedCurrency}
                            />
                          );
                        })}
                      {delivery[item.name] &&
                        !item.disabled &&
                        item.name === 'delivery_shipping' && (
                          <div className={styles.byPost}>
                            <TextField
                              className={styles.searchInput}
                              style={{ width: '100%' }}
                              placeholder="Country"
                              data-type="search"
                              InputProps={{
                                style: { cursor: 'pointer' },
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <SearchIcon style={{ width: 19.5, height: 19.5 }} />
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <Box className={styles.checkboxItems}>
                              <Controller
                                name="deliveryShippingWorldwide"
                                defaultValue=""
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                  <>
                                    <FormControlLabel
                                      style={{
                                        display: 'flex',
                                        color: '#143471',
                                      }}
                                      control={
                                        <Checkbox
                                          checkedIcon={
                                            <CheckBoxOutlinedIcon sx={{ fill: '#212121' }} />
                                          }
                                          checked={value}
                                          onChange={onChange}
                                        />
                                      }
                                      label={
                                        <Typography className={styles.checkBoxLabel}>
                                          Worldwide
                                        </Typography>
                                      }
                                    />
                                  </>
                                )}
                              />
                            </Box>
                          </div>
                        )}
                      {delivery[item.name] && item.name === 'delivery_hand' && (
                        <div
                          className={styles.byPost}
                          style={{ display: 'flex', paddingLeft: '25px' }}
                        >
                          <Controller
                            name="handAddresses.address"
                            defaultValue={getValues('handAddresses.address')}
                            rules={{ required: true }}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <Box style={{ width: '100%' }}>
                                <TextField
                                  className={styles.locationInputSeller}
                                  style={{ width: '100% !important' }}
                                  placeholder="Location"
                                  data-type="location"
                                  value={value}
                                  onChange={onChange}
                                  onClick={() => setIsLocationModal('handAddresses')}
                                />
                                {errors && errors.handAddresses && errors.handAddresses.address && (
                                  <Box>
                                    <Typography className={styles.error}>
                                      Please fill out the missing fields.
                                    </Typography>
                                  </Box>
                                )}
                              </Box>
                            )}
                          />
                          <Box>
                            <TextField
                              {...register(`handAddresses.distance`, {
                                required: true,
                              })}
                              className={styles.costInput}
                              placeholder="Distance"
                              data-type="distance"
                              type="number"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    <Controller
                                      name={'handAddresses.distance_unit_id'}
                                      defaultValue={
                                        unitsList.find((item) => item.name === defaultUnite).id
                                      }
                                      control={control}
                                      render={({ field: { onChange, value } }) => (
                                        <>
                                          <Select
                                            onChange={onChange}
                                            value={value}
                                            style={{ display: 'flex' }}
                                            sx={{
                                              '.MuiOutlinedInput-notchedOutline': {
                                                border: '0 !important',
                                              },
                                            }}
                                            renderValue={(value) =>
                                              value ? (
                                                <div style={{ display: 'flex' }}>
                                                  <p>
                                                    {
                                                      unitsList.find((item) => item.id === value)
                                                        .name
                                                    }
                                                  </p>
                                                </div>
                                              ) : (
                                                ''
                                              )
                                            }
                                          >
                                            {unitsList.length &&
                                              unitsList.map((item, index) => (
                                                <MenuItem key={index} value={item.id}>
                                                  <p>{item.name}</p>
                                                </MenuItem>
                                              ))}
                                          </Select>
                                        </>
                                      )}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                            {errors && errors.handAddresses && errors.handAddresses.distance && (
                              <Box>
                                <Typography className={styles.error}>
                                  Please fill out the missing fields.
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </div>
                      )}
                    </>
                  )}
                />
              </>
            ))}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: '3px',
              }}
            >
              <ModalAdd />
            </div>
          </div>
        </div>
      </div>
      <LocationModal
        title="Choose your location"
        isModelOpen={isLocationModal}
        handleClose={() => setIsLocationModal('')}
        handleApply={handleApplyLocation}
      />
    </>
  );
};
