import React, { useEffect, useState } from 'react';
import SearchNav from '../Search';
import { useLazyQuery } from '@apollo/client';
import io from 'socket.io-client';
import NavbarLink from './NavbarLink';
import NavBarNotificationButton from './NavBarNotificationButton';
import {
  Button,
  HomeIcon,
  PricingIcon,
  OrdersIcon,
  CommunityIcon,
  NotificationsIcon,
  MessageIcon,
} from 'tt-ui-kit';

import styles from './Navbar.module.scss';
import NavbarUnauthDrawer from './NavbarUnauthDrawer';
import MenuIcon from './assets/MenuIcon';
import LoginIcon from './assets/LoginIcon';

const Navbar = (props) => {
  const {
    avaProps,
    getNotificationsQuery,
    setAnchorLoginEl,
    user,
    notificationSocket,
    setShowNeedKYCModal,
    setIsAgreementModalOpen,
    logOut,
    redirectDomain,
    marketTTUrl,
    mode,
    qaTTUrl,
  } = props;

  const [notifications, setNotifications] = useState([]);
  const [unauthDrawerOpen, setUnauthDrawerOpen] = useState(false);

  const handleLoginClick = (event) => {
    event.preventDefault();
    setAnchorLoginEl(event.currentTarget);

    event.stopPropagation();
  };

  const handleClick = (link) => {
    window.location.href = link;
  };

  const [getNotifications] = useLazyQuery(getNotificationsQuery);

  const getUserNotifications = async () => {
    if (getNotificationsQuery && user?.id) {
      const allNotifications = await getNotifications({
        variables: { filter: 'unread' },
      });
      if (allNotifications.data && allNotifications.data.notifications) {
        setNotifications(allNotifications.data.notifications);
      }
    }
  };

  let socketIo;
  let socketIoHandler;
  const handleConnectSocket = (id) => {
    if (socketIo && socketIoHandler) {
      socketIo.removeAllListeners(
        'Illuminate\\Notifications\\Events\\BroadcastNotificationCreated'
      );
    }

    if (id) {
      socketIo = io(notificationSocket, {
        reconnectionDelayMax: 10000,
        extraHeaders: {
          user_id: id,
        },
        withCredentials: true,
        transports: ['websocket'],
      });
      if (socketIo) {
        socketIoHandler = socketIo.on(
          'Illuminate\\Notifications\\Events\\BroadcastNotificationCreated',
          (data) => {
            setNotifications((current) => [data, ...current]);
          }
        );
      }
    }
  };

  useEffect(() => {
    handleConnectSocket(user?.id);
  }, [user]);

  useEffect(() => {
    getUserNotifications().then();
  }, []);

  return (
    <header>
      <div className={styles.navbarContent}>
        <div className={styles.navbarLogoSearchBox}>
          <div
            onClick={() =>
              handleClick(redirectDomain ? `${redirectDomain}/dashboard/feed` : '/dashboard/feed')
            }
          >
            <div className={styles.navbarLogo}>
              <img
                src="https://prod-sso-public-tt.s3.eu-central-1.amazonaws.com/logo/nav-logo.svg"
                alt=""
                className={styles.logoImg}
              />
            </div>
          </div>
          <div>
            <SearchNav />
          </div>
        </div>
        <nav className={styles.navbarLinks}>
          <NavbarLink
            Icon={HomeIcon}
            text="HOME"
            href={redirectDomain ? `${redirectDomain}/dashboard/feed` : '/dashboard/feed'}
          />
          <NavbarLink
            Icon={PricingIcon}
            text="PRICING"
            href={redirectDomain ? `${redirectDomain}/dashboard/pricing` : '/dashboard/pricing'}
          />
          <NavbarLink
            Icon={CommunityIcon}
            text="COMMUNITY"
            href={redirectDomain ? `${redirectDomain}/dashboard/community` : '/dashboard/community'}
            onClick={!user.id ? (e) => handleLoginClick(e) : null}
          />
          <NavbarLink
            Icon={OrdersIcon}
            text="ORDERS"
            href={marketTTUrl ? `${marketTTUrl}/dashboard/orders` : '/dashboard/orders'}
            onClick={!user.id ? (e) => handleLoginClick(e) : null}
          />
          <NavbarLink
            Icon={MessageIcon}
            text="MESSAGING"
            href={redirectDomain ? `${redirectDomain}/dashboard/messaging` : '/dashboard/messaging'}
            onClick={!user.id ? (e) => handleLoginClick(e) : null}
          />
          <NavBarNotificationButton
            redirectDomain={redirectDomain}
            Icon={NotificationsIcon}
            text="NOTIFICATIONS"
            notifications={notifications}
            getUserNotifications={getUserNotifications}
            user={user}
            onClick={!user.id ? (e) => handleLoginClick(e) : null}
            setShowNeedKYCModal={setShowNeedKYCModal}
            setIsAgreementModalOpen={setIsAgreementModalOpen}
            logOut={logOut}
          />
        </nav>
        <div className={styles.mobileHeader}>
          <div className={styles.mobileLinks}>
            {user.id ? (
              <button
                onClick={() =>
                  handleClick(
                    redirectDomain
                      ? `${redirectDomain}/dashboard/notification`
                      : '/dashboard/notification'
                  )
                }
                type="button"
                className={
                  window.location.pathname === '/dashboard/notification'
                    ? styles.notificButtonActive
                    : styles.notificButton
                }
              >
                <NotificationsIcon
                  className={
                    window.location.pathname === '/dashboard/notification'
                      ? styles.notificIconActive
                      : styles.notificIcon
                  }
                />
              </button>
            ) : (
              <div className={styles.mobileBtnsContainer}>
                <button onClick={handleLoginClick} type="button" className={styles.notificButton}>
                  <LoginIcon style={{ width: 24, height: 24, display: 'block' }} />
                </button>

                <button
                  onClick={() => setUnauthDrawerOpen(true)}
                  type="button"
                  className={styles.notificButton}
                >
                  <MenuIcon style={{ width: 24, height: 24, display: 'block' }} />
                </button>

                <NavbarUnauthDrawer
                  open={unauthDrawerOpen}
                  setOpen={setUnauthDrawerOpen}
                  redirectDomain={redirectDomain}
                  mode={mode}
                  qaTTUrl={qaTTUrl || ''}
                />
              </div>
            )}
          </div>
          <div style={{ cursor: 'pointer' }}>
            {user.id ? (
              avaProps
            ) : (
              <Button type="default" className={styles.signNavbarButton} onClick={handleLoginClick}>
                SIGN IN/UP
              </Button>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
