import React, { useEffect, useState } from 'react';
import styles from './ItemCounter.module.scss';
import { Button, LoaderOn, ZoomInIcon, ZoomOutIcon } from 'tt-ui-kit';
import { openNotification } from 'tt-ui-lib/core';
import { useMutation } from '@apollo/client';
import { CREATE_AND_UPDATE_USER_CART } from '../../../../api';
import { useDebounce } from '../../../../utils/useDebounce';

const ItemCounter = ({ id, priceId, initialCount, price, onUpdate = null, unitName }) => {
  const [count, setCount] = useState(0);
  const [inputValue, setInputValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdateAvailable, setIsUpdateAvailable] = useState(false);
  const debouncedCount = useDebounce(count, 1000);

  const [updateItemRequest] = useMutation(CREATE_AND_UPDATE_USER_CART);

  useEffect(() => {
    setCount(initialCount);
    setInputValue(initialCount);
  }, []);

  const deleteFromCart = () => {
    (async () => {
      setIsLoading(true);

      const input = {
        product_id: id,
        product_price_id: priceId,
        quantity: 0,
      };

      const response = await updateItemRequest({
        variables: {
          input: input,
        },
      });

      if (response?.data?.addToUserCart === null) {
        openNotification({
          type: 'success',
          message: 'Item deleted from your cart',
        });
        setCount(0);
        setInputValue(0);
        onUpdate && onUpdate();
      } else {
        openNotification({
          type: 'error',
          message: 'Error while deleting item, try again later',
        });
      }
      setIsLoading(false);
    })();
  };

  const editCountInCart = (value) => {
    if (value === 0) {
      setIsLoading(false);
      return;
    }

    (async () => {
      setIsLoading(true);

      const input = {
        product_id: id,
        product_price_id: priceId,
        quantity: value,
      };

      const response = await updateItemRequest({
        variables: {
          input: input,
        },
      });

      if (response?.data?.addToUserCart) {
        openNotification({
          type: 'success',
          message: 'Item quantity updated in your cart',
        });
      } else {
        openNotification({
          type: 'error',
          message: 'Error while updating item quantity, try again later',
        });
      }
      setIsLoading(false);
    })();
  };

  const changeCountValue = (newValue) => {
    setIsUpdateAvailable(true);
    if (newValue === count && newValue !== 0) {
      return;
    }

    if (newValue > price?.quantity_all && price.availability !== 'always_available') {
      openNotification({
        type: 'warning',
        message:
          'Unfortunately, you cannot add more products to the cart than are currently available',
        duration: 5000,
      });
      return;
    }

    if (newValue < (price.min_order_quantity || 1) || newValue === 0) {
      deleteFromCart();
      return;
    }
    setIsLoading(true);
    let newQuantity = newValue;
    if (price.availability === 'always_available') {
      newQuantity =
        newValue <= (price.min_order_quantity || 1) ? price.min_order_quantity || 1 : newValue;
    } else {
      newQuantity =
        newValue <= (price.min_order_quantity || 1)
          ? price.min_order_quantity || 1
          : newValue >= (price.quantity_all || price.min_order_quantity || 1)
            ? price.quantity_all || price.min_order_quantity || 1
            : newValue;
    }
    setCount(newQuantity);
    setInputValue(newQuantity);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleBlurOrEnter = () => {
    const newValue = parseInt(inputValue, 10);
    if (!isNaN(newValue)) {
      changeCountValue(newValue);
    } else {
      openNotification({
        type: 'error',
        message: 'Please enter a valid number',
      });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleBlurOrEnter();
    }
  };

  useEffect(() => {
    if (debouncedCount && debouncedCount > 0 && isUpdateAvailable) {
      editCountInCart(debouncedCount);
    }
  }, [debouncedCount]);

  return (
    <div className={styles.container} onMouseDown={(e) => e.stopPropagation()}>
      {(isLoading || count === 0) && <LoaderOn />}
      <Button type="icon" className={styles.button} onMouseDown={() => changeCountValue(count - 1)}>
        <ZoomOutIcon style={{ width: 20, height: 20 }} />
      </Button>

      <input
        value={inputValue}
        type="number"
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        onBlur={handleBlurOrEnter}
        className={styles.input}
      />

      <Button type="icon" className={styles.button} onMouseDown={() => changeCountValue(count + 1)}>
        <ZoomInIcon style={{ width: 20, height: 20 }} />
      </Button>
    </div>
  );
};

export default ItemCounter;
