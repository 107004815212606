import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styles from './OrderItemCostumer.module.scss';
import { styled, Menu, IconButton } from '@mui/material';
import { MoreVIcon, BrakeWarningIcon, Button, WarningIcon } from 'tt-ui-kit';
import { openNotification } from 'tt-ui-lib/core';
import Image from '../../../../Image';
import { ScrollContainer } from 'react-indiana-drag-scroll';
import { orderStatusesCostumer } from '../orderStatusesCostumer';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { getOrderReceipt } from '../../../../../subscribes/endpoints/order';
import { getAvatarPathBySize } from '../../../../../utils/appUtils';
import { Timer } from '../../../CartPage/OrderPlacedPaid/Timer';

const OrderItemCostumer = ({ order, destroyOrder, setOrdersData }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const StyledMenu = styled((props) => (
    <Menu
      id="pickup-options-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'menu-button',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      boxShadow: '0px 4px 32px rgba(0, 0, 0, 0.08)',
      borderRadius: '12px',
      margin: '24px 20px',
      '& .MuiList-root': {
        padding: 0,
      },
    },
  }));

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event?.stopPropagation();
    setAnchorEl(null);
  };

  const renderOrderProductImages = () => {
    if (order.orderItems && order.orderItems.length > 0) {
      return (
        <ScrollContainer hideScrollbars={false} className={styles.scrollContainer}>
          <Box className={styles.slider}>
            {order.orderItems.map((product) => (
              <Box key={product.id} className={styles.sliderImage}>
                <Image
                  className={styles.image}
                  src={
                    getAvatarPathBySize(product?.shopProduct?.logos, 'mediumPath') ||
                    '/images/market-place/no_image.png'
                  }
                />
              </Box>
            ))}
          </Box>
        </ScrollContainer>
      );
    }
    return null;
  };

  const orderOptionActions = {
    delete: async () => {
      await destroyOrder(order.id);
      handleClose();
    },
    openDispute: () => {
      //TODO api for order open Dispute
      openNotification({
        type: 'success',
        message: 'openDispute',
      });
    },
    download: async () => {
      const data = {
        id: order.id,
      };
      getOrderReceipt(data, 'customer', 'order')
        .then(() => {
          openNotification({
            type: 'success',
            message: `Download order, succeeded`,
          });
        })
        .catch(() => {
          openNotification({
            type: 'error',
            message: `Error while download order, try again later`,
          });
        });
    },
  };

  return (
    <Box className={styles.itemContainer}>
      <Box
        className={styles.header}
        onClick={() => navigate(`/dashboard/orders/outgoing/${order.id}`)}
      >
        {orderStatusesCostumer[order.status].options && (
          <>
            <IconButton className={styles.menuButton} onClick={handleClick}>
              <MoreVIcon style={{ width: 20, height: 20, flexShrink: 0, fontSize: 20 }} />
            </IconButton>
            <StyledMenu>
              <Box className={styles.optionsMenu}>
                {orderStatusesCostumer[order.status].options.map((item) => (
                  <Box
                    key={item.id}
                    className={styles.menuItem}
                    onClick={(event) => {
                      event.stopPropagation();
                      orderOptionActions[item.action]();
                    }}
                  >
                    {item.icon}
                    <Typography>{item.title}</Typography>
                  </Box>
                ))}
              </Box>
            </StyledMenu>
          </>
        )}
        <Typography className={styles.orderNumberBold}>№ {order.id}</Typography>
        <Box className={styles.statusAndOptions}>
          <Box
            sx={{ backgroundColor: orderStatusesCostumer[order.status]?.color || '#143471' }}
            className={styles.status}
          >
            {orderStatusesCostumer[order.status]?.status}
          </Box>
        </Box>
      </Box>
      <Box className={styles.orderOptions}>
        <Box className={styles.optionColumn}>
          <Box className={styles.optionRow}>
            {orderStatusesCostumer[order.status].status === 'Pending payment' && (
              <Box className={styles.timerBlock}>
                <Box className={styles.warningInfoBlock}>
                  <WarningIcon style={{ width: 24, height: 24 }} />
                  <Typography className={styles.title}>
                    You need to pay for the order within 30 minutes
                  </Typography>
                </Box>
                <Timer
                  startDate={order.order?.payment_process_start}
                  orderId={order.id}
                  setOrdersData={setOrdersData}
                  minutes={30}
                />
              </Box>
            )}
          </Box>
          <Box className={styles.optionRow}>
            <Typography className={styles.optionTitle}>Order date:</Typography>
            {moment(order.created_at).format('DD.MM.YYYY')}
          </Box>
          <Box className={styles.optionRow}>
            <Typography className={styles.optionTitle}>Delivery date:</Typography>
            <Typography className={styles.optionValue}>12.07.2023</Typography>
          </Box>
          <Box className={styles.optionRow}>
            <Typography className={styles.optionTitleBold}>Total:</Typography>
            <Typography className={styles.optionValueBold}>
              $ {parseFloat(order?.orderGroupPriceWithFee?.groupPrice.toFixed(2))}
            </Typography>
          </Box>
        </Box>
        {order.id && renderOrderProductImages()}
      </Box>
      {orderStatusesCostumer[order.status].status === 'Completed' && (
        <Box className={styles.disputeBlock}>
          <Box className={styles.leftBlock}>
            <Box className={styles.disputeBtn}>
              <BrakeWarningIcon />
              <Typography className={styles.text}>Dispute</Typography>
            </Box>
            <Typography className={styles.orderNumber}>№ {order.id}</Typography>
          </Box>
          <Typography className={styles.rightBlock}>There&apos;s no solution yet</Typography>
        </Box>
      )}
      {orderStatusesCostumer[order.status].status === 'Auto-order' && (
        <Box className={styles.autoOrderButtons}>
          <Button type="mainButton">Stop Auto-order</Button>
          <Button type="mainButton">Edit Auto-order</Button>
        </Box>
      )}
    </Box>
  );
};

export default OrderItemCostumer;
