const requestCustomizationStatus = {
  color: '#3F62BB',
  status: 'Customization Request',
  buttonGroups: {
    leftAside: [
      {
        id: 2,
        title: 'Cancel',
        type: 'mainButton',
        action: 'cancelServiceOrder',
      },
    ],
    rightAside: [
      {
        id: 2,
        title: 'Propose new changes',
        type: 'mainButton',
        className: 'proposeBtn',
        action: 'proposeNewChanges',
      },
      {
        id: 2,
        title: 'Accept',
        type: 'primary',
        action: 'accept',
      },
    ],
  },
};

const canceledStatus = {
  color: '#D32F2F',
  status: 'Cancelled',
  buttonGroups: {
    leftAside: [],
    rightAside: [],
  },
};

export const orderServiceStatuses = {
  request_customization: requestCustomizationStatus,
  canceled: canceledStatus,
};
