import React, { useContext, useEffect, useState } from 'react';
import styles from './ProductItemPage.module.scss';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {
  Box,
  ButtonBase,
  Hidden,
  InputBase,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Snackbar,
  Alert,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import Image from '../../Image';
import DeleteItemModal from 'components/deleteItemModal';
import DescriptionTab from './ProductItemTabs/DescriptionTab';
import AboutCompanyTab from './ProductItemTabs/AboutCompanyTab';
import DeliveryMethodsTab from './ProductItemTabs/DeliveryMethodsTab';
import { ScrollContainer } from 'react-indiana-drag-scroll';
import 'react-indiana-drag-scroll/dist/style.css';
import ReactImageMagnify from 'react-image-magnify';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  CREATE_AND_UPDATE_USER_CART,
  DELETE_SHOP_PRODUCT,
  LOAD_SHOP_PRODUCT_LOGOS,
} from '../../../api';
import { AppAvatar } from '../../avatar';
import { Button, openNotification } from 'tt-ui-lib/core';
import { CloseIcon } from 'tt-ui-kit';
import { useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import PartialLoader from '../../layout/PartialLoader';
import userContext from '../../../context/User/userContext';
import { SignInUpModalWrapper } from '../../Modals/SignInUpModal/SignInUpModal';
import ItemCounter from '../ProductsPage/ItemCounter';
import { getAvatarPathBySize } from '../../../utils/appUtils';
import SearchProduct from 'components/SearchProduct';

const tabsSx = {
  '.MuiTabs-flexContainer': {
    justifyContent: 'flex-start',
    gap: '40px !important',
  },
};

let TABS = [
  {
    label: 'Description',
  },
  {
    label: 'About company',
  },
  {
    label: 'Delivery methods',
  },
  {
    label: 'Customer reviews',
  },
  {
    label: 'Traceability',
  },
];

const COLORS = [
  {
    name: 'Green',
    color: '#92FF8F',
  },
  {
    name: 'Purple',
    color: '#C4BFFF',
  },
  {
    name: 'Yellow',
    color: '#FCFF77',
  },
  {
    name: 'Orange',
    color: '#FFBA68',
  },
  {
    name: 'Indian Red',
    color: '#FF6D6D',
  },
];

const ProductItemPage = ({ productData, isSeller, loading, getProduct }) => {
  const { id } = useParams();
  console.log(id);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeImage, setActiveImage] = useState(null);
  const [activeColor, setActiveColor] = useState('Green');
  const [activeSize, setActiveSize] = useState({});
  const [deliveryMethods, setDeliveryMethods] = useState({});
  const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [outOfStock, setOutOfStock] = useState(false);
  const [availableToBuy, setAvailableToBuy] = useState(false);
  const [inputValue, setInputValue] = useState(0);

  const [isDataFetching, setIsDataFetching] = useState(false);
  const [countValue, setCountValue] = useState(1);

  const [alertText, setAlertText] = useState('');
  const [sizes, setSizes] = useState([]);
  const [logos, setLogos] = useState([]);
  const [deleteProductModal, setDeleteProductsModal] = useState(false);
  const [selectedPage, setSelectedPage] = useState('Description');
  const [pageStatus, setPageStatus] = useState('');
  const [isInCart, setIsInCart] = useState(false);
  const [cardInfo, setCardInfo] = useState(false);

  const domainValue = process.env.REACT_APP_DOMAIN;
  const userId = Cookies.get('userId', { domain: domainValue });
  const { user } = useContext(userContext);

  const [deleteShopProduct] = useMutation(DELETE_SHOP_PRODUCT);
  const [loadProductLogos] = useLazyQuery(LOAD_SHOP_PRODUCT_LOGOS);
  const [anchorLoginEl, setAnchorLoginEl] = useState(null);
  const open = Boolean(anchorLoginEl);
  const [addItemToCartRequest] = useMutation(CREATE_AND_UPDATE_USER_CART);

  useEffect(() => {
    if (productData.id) {
      if (productData.productPrices.length) {
        setSizes(productData.productPrices);
        const activeSizeData = productData.productPrices[0];
        setActiveSize(activeSizeData);
        setDeliveryMethods({
          delivery_digital: activeSizeData.delivery_digital,
          delivery_hand: activeSizeData.delivery_hand,
          delivery_pickup: activeSizeData.delivery_pickup,
          delivery_shipping: activeSizeData.delivery_shipping,
        });
        (async () => {
          await getProductImages();
        })();
      }
    }
  }, [productData]);

  useEffect(() => {
    if (activeSize.availableToBuy) {
      setAvailableToBuy(activeSize.availability);
    }
  }, [activeSize]);

  const handleLoginClose = () => {
    setAnchorLoginEl(null);
  };
  const handleLoginClick = (event) => {
    event.preventDefault();
    setAnchorLoginEl(event.currentTarget);

    event.stopPropagation();
  };
  const addToCart = (event, withRedirect = false) => {
    (async () => {
      if (!user.id) {
        handleLoginClick(event);
        return false;
      }
      if (
        availableToBuy &&
        (activeSize.availability === 'always_available' ||
          activeSize.min_order_quantity <= activeSize.quantity_all)
      ) {
        const input = {
          product_id: id,
          product_price_id: activeSize.id,
          quantity: countValue,
        };

        const req = await addItemToCartRequest({
          variables: {
            input: input,
          },
        });

        if (req?.data?.addToUserCart) {
          openNotification({
            type: 'success',
            message: 'Product added to your cart',
          });
          const inCart = req.data.addToUserCart.quantity > 0;
          setCardInfo(req.data.addToUserCart.quantity);
          setIsInCart(inCart);
          withRedirect ? navigate('/dashboard/cart') : setIsDataFetching(true);
        } else {
          openNotification({
            type: 'error',
            message: 'Error while adding product to your cart, try again later',
          });
        }
      } else {
        openNotification({
          type: 'warning',
          message: 'Not available to buy',
        });
      }
    })();
  };

  const getProductImages = async () => {
    const res = await loadProductLogos({
      variables: {
        shop_product_id: id,
      },
      fetchPolicy: 'network-only',
    });
    if (res.data && res.data.loadAllShopProductLogos.length) {
      setLogos(res.data.loadAllShopProductLogos);
      setActiveImage(res.data.loadAllShopProductLogos[0]);
    }
  };

  const deleteProduct = async () => {
    const res = await deleteShopProduct({ variables: { id: productData.id } });
    if (res.data && res.data.destroyShopProduct) {
      setDeleteProductsModal(false);
      let companyId = productData.company_id;
      navigate(`/dashboard/company/${companyId}/store?activeTab=products`, {
        replace: true,
        state: {
          isOpenNotification: true,
          openNotification: openNotification({
            type: 'success',
            message: 'Shop Product was successfully deleted!',
          }),
        },
      });
    }
  };

  useEffect(() => {
    if (activeSize.availability === 'out_of_stock' || activeSize.quantity_all === 0) {
      setOutOfStock(true);
    } else {
      setOutOfStock(false);
    }
    const inCart = activeSize ? activeSize?.userCart?.quantity > 0 || false : false;
    setIsInCart(inCart);
  }, [activeSize]);

  useEffect(() => {
    if (isDataFetching && userId) {
      (async () => {
        await getProductImages();
      })();

      setIsDataFetching(false);
    }
  }, [isDataFetching]);

  useEffect(() => {
    (async () => {
      await getProductImages();
    })();
    const actionStatus = searchParams.get('action');
    if (actionStatus === 'created' || actionStatus === 'updated') {
      setPageStatus(actionStatus);
      setAlertText(`Product ${actionStatus} successfully`);
      setOpenSuccessAlert(true);
      searchParams.delete('action');
      setSearchParams(searchParams);
    }
  }, [user]);

  useEffect(() => {
    if (activeSize && activeSize.min_order_quantity) {
      setCountValue(activeSize.min_order_quantity);
      setInputValue(activeSize.min_order_quantity);
    }
  }, [activeSize]);

  const changeCountValue = (value) => {
    if (activeSize.availability !== 'out_of_stock') {
      let quantity = activeSize?.quantity_all || 0;
      console.log(quantity);
      if (value <= quantity && value >= 0) {
        setCountValue(value);
        setInputValue(value);
      } else {
        openNotification({
          type: 'warning',
          message:
            'Unfortunately, you cannot add more products to the cart than are currently available',
          duration: 5000,
        });
        setCountValue(quantity);
        setInputValue(quantity);
      }
    }
  };

  const handleBlurOrEnter = () => {
    const newValue = parseInt(inputValue, 10);
    if (!isNaN(newValue)) {
      changeCountValue(newValue);
    } else {
      openNotification({
        type: 'error',
        message: 'Please enter a valid number',
      });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleBlurOrEnter();
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleChangePage = (event, newValue) => {
    setSelectedPage(newValue);
  };

  const handleChangePageSelect = (event) => {
    handleChangePage(event, event.target.value);
  };

  const backToPrevPage = () => {
    navigate('/dashboard/marketplace/list/product?activeTab=products');
    // if (pageStatus === 'created') {
    //   let startPage = searchParams.get('create-from');
    //   if (startPage === 'company') {
    //     navigate(`/dashboard/company/${productData.company_id}/edit`);
    //   } else {
    //     navigate(`/dashboard/company/${productData.company_id}/store`);
    //   }
    // } else {
    //   let prevPage = searchParams.get('from');
    //   if (prevPage === 'store') {
    //     navigate(`/dashboard/company/${productData.company_id}/store?activeTab=products`);
    //   } else if (prevPage === 'proposals') {
    //     navigate(`/dashboard/company/${productData.company_id}?activeTab=proposals`);
    //   } else if (prevPage === 'cart') {
    //     navigate(`/dashboard/cart`);
    //   } else if (prevPage === 'marketplace') {
    //     navigate('/dashboard/marketplace/list/product?activeTab=products');
    //   } else {
    //     navigate(-1);
    //   }
    // }
  };

  return (
    <>
      <PartialLoader loading={loading} isLightContainer />
      <Box className={styles.pageContentBox}>
        {!isSeller ? (
          <Box className={styles.filterContent}>
            <Box className={styles.filterBar} sx={{ flexGrow: 1 }}>
              <ButtonBase
                className={styles.backBtn}
                sx={{
                  '.MuiTouchRipple-root': {
                    display: 'none',
                  },
                }}
                onClick={backToPrevPage}
              >
                <ArrowBackIosNewIcon fontSize="medium" style={{ color: '#212121' }} />
                <Typography className={styles.productButtonOutlineText}>Back</Typography>
              </ButtonBase>
              <SearchProduct user={user.id} />
              <Box className={styles.mobileFilterBar}>
                <ButtonBase
                  className={styles.backBtn}
                  sx={{
                    '.MuiTouchRipple-root': {
                      display: 'none',
                    },
                  }}
                  onClick={backToPrevPage}
                >
                  <ArrowBackIosNewIcon fontSize="medium" style={{ color: '#212121' }} />
                  <Typography className={styles.productButtonOutlineText}>Back</Typography>
                </ButtonBase>
                <Box className={styles.shoppingCart} onClick={() => navigate('/dashboard/cart')}>
                  <Image src="/images/market-place/shopping-cart.svg" width={24} height={24} />
                </Box>
              </Box>
              <Box className={styles.shoppingCart} onClick={() => navigate('/dashboard/cart')}>
                <Image src="/images/market-place/shopping-cart.svg" width={24} height={24} />
              </Box>
            </Box>
          </Box>
        ) : (
          <Box className={styles.statusBlock}>
            <ButtonBase
              className={styles.backBtn}
              sx={{
                '.MuiTouchRipple-root': {
                  display: 'none',
                },
              }}
              onClick={backToPrevPage}
            >
              <ArrowBackIosNewIcon fontSize="medium" style={{ color: '#212121' }} />
              <Typography className={styles.productButtonOutlineText}>Back</Typography>
            </ButtonBase>
            <Box className={styles.productStatus}>{productData.status}</Box>
          </Box>
        )}
        <Box className={styles.productContent}>
          <Box className={styles.productHeader}>
            <Box className={styles.companyInfo}>
              <AppAvatar
                userName={productData?.company?.company_name}
                src={getAvatarPathBySize(productData?.company?.logos, 'smallPath')}
                size="xl"
                nav={0}
              />
              <a
                href={`/dashboard/company/${productData?.company?.id}/store`}
                target="_blank"
                rel="noreferrer"
                onClick={(e) => e.stopPropagation()}
                style={{ color: 'inherit' }}
              >
                <Typography className={styles.companyName}>
                  {productData?.company?.company_name}
                </Typography>
              </a>
            </Box>
            {isSeller && !productData.deleted_at && (
              <Box className={styles.headerActions}>
                <Button
                  type="default"
                  className={styles.actionBtn}
                  onClick={() =>
                    navigate(
                      `/dashboard/proposals/product/${productData.id}?company=${productData.company_id}&from=product`
                    )
                  }
                >
                  <Image src="/images/pen-icon.svg" height={18} width={18} />
                </Button>
                <Button
                  type="default"
                  className={styles.actionBtn}
                  onClick={() => setDeleteProductsModal(true)}
                >
                  <Image src="/images/delete-icon.svg" height={18} width={18} />
                </Button>
              </Box>
            )}
          </Box>
          <Box className={styles.productInfoContainer}>
            <Box className={styles.productImages}>
              <Box className={styles.selectImage}>
                {activeImage ? (
                  <ReactImageMagnify
                    {...{
                      smallImage: {
                        alt: activeImage?.original_name,
                        isFluidWidth: true,
                        src: activeImage?.path,
                      },
                      largeImage: {
                        src: activeImage?.path,
                        width: 1200,
                        height: 1205,
                      },
                      enlargedImageContainerStyle: {
                        zIndex: '1500',
                      },
                      enlargedImageContainerDimensions: {
                        width: '100%',
                        height: '100%',
                      },
                      imageStyle: {
                        width: '-webkit-fill-available',
                        height: 426,
                        maxHeight: 426,
                      },
                    }}
                  />
                ) : (
                  <Image
                    src="/images/market-place/no_image.png"
                    fullWidth={true}
                    fullHeight={true}
                  />
                )}
                {!isSeller && (
                  <Box className={styles.favorites}>
                    <Image src="/images/market-place/favorite.svg" width={20} />
                  </Box>
                )}
              </Box>
              {logos && logos.length ? (
                <ScrollContainer mouseScroll={{ buttons: [1] }}>
                  <Box className={styles.slider}>
                    {logos.map((item) => (
                      <Box
                        key={item.id}
                        className={styles.sliderImage}
                        onClick={() => {
                          setActiveImage(item);
                        }}
                        style={{
                          border:
                            activeImage === item ? '1px solid #1F4A9D' : '1px solid transparent',
                        }}
                      >
                        <Image className={styles.image} src={item.path} alt={item.original_name} />
                      </Box>
                    ))}
                  </Box>
                </ScrollContainer>
              ) : null}
            </Box>
            {productData && productData.logos && productData.logos.length ? (
              <Swiper
                pagination
                modules={[Pagination]}
                className={styles.productImageSwiper}
                spaceBetween={20}
              >
                {productData.logos.map((item) => (
                  <SwiperSlide key={item.id} className={styles.productImageSwiperSlide}>
                    <Image className={styles.image} src={item.path} alt={item.original_name} />
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : null}
            <Box className={styles.productInfo}>
              <Typography className={styles.productName}>{productData.name}</Typography>
              <Box className={styles.productReviewContainer}>
                {/*<Box className={styles.productReviewBlock}>
                  <Box className={styles.productReview}>
                    <Image src="/images/market-place/review-star.svg" width={24} />
                    <Image src="/images/market-place/review-star.svg" width={24} />
                    <Image src="/images/market-place/review-star.svg" width={24} />
                    <Image src="/images/market-place/review-star.svg" width={24} />
                    <Image src="/images/market-place/review-star.svg" width={24} />
                  </Box>
                  <Typography className={styles.productReviewCount}>21 reviews</Typography>
                  <Box className={styles.productSoldCountBlock}>
                    <Image src="/images/market-place/check.svg" width={11} />
                    <Typography className={styles.productSoldCount}>11234441 sold</Typography>
                  </Box>
                </Box>*/}
              </Box>
              {/*<Box className={styles.productSettingsContainer}>
                <Typography className={styles.label}>Color: {activeColor}</Typography>
                <Box className={styles.productSettingsItemList}>
                  {COLORS.map((item) => (
                    <Box
                      key={item.color}
                      className={styles.productSettingsColorContainer}
                      onClick={() => {
                        setActiveColor(item.name);
                      }}
                      style={{
                        border:
                          activeColor === item.name ? '1px solid #1F4A9D' : '1px solid #EFEFEF',
                      }}
                    >
                      <Box
                        className={styles.productSettingsColor}
                        style={{ backgroundColor: item.color }}
                      />
                    </Box>
                  ))}
                </Box>
              </Box>*/}
              {sizes && sizes.length && !productData.deleted_at ? (
                <Box className={styles.productSettingsContainer}>
                  <Typography className={styles.label}>Option:</Typography>
                  <Box className={styles.productSettingsItemList}>
                    {sizes.map((item) => (
                      <Box
                        key={item.id}
                        className={styles.productSettingsSizeContainer}
                        onClick={() => {
                          setActiveSize(item);
                        }}
                        style={{
                          border:
                            activeSize.id === item.id &&
                            item.availability !== 'out_of_stock' &&
                            item.quantity_all !== 0
                              ? '1px solid #1F4A9D'
                              : '1px solid #EFEFEF',
                        }}
                      >
                        <Typography
                          className={[
                            styles.productSettingsSize,
                            item.availability === 'out_of_stock' || item.quantity_all === 0
                              ? styles.productSettingsSizeDisabled
                              : '',
                          ]}
                        >
                          {`${item.unit_value} ${item.unit.name}`}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              ) : null}
              {!productData.deleted_at && (
                <>
                  {!outOfStock ? (
                    <>
                      {activeSize.has_discount_price ? (
                        <Box className={styles.discountPriceBlock}>
                          <Box className={styles.oldPriceBlock}>
                            <Typography className={styles.oldPrice}>
                              {activeSize?.currency?.symbol}
                              {activeSize?.price}
                            </Typography>
                            <Box className={styles.oldPriceLine} />
                          </Box>
                          <Typography className={styles.discountPrice}>
                            {activeSize?.currency?.symbol || '$'}
                            {activeSize?.discount_price || '-'}
                            <span>/{activeSize?.unit?.name}</span>
                          </Typography>
                        </Box>
                      ) : (
                        <Typography className={styles.productPrice}>
                          {activeSize?.currency?.symbol}
                          {activeSize?.price} <span>/{activeSize?.unit?.name}</span>
                        </Typography>
                      )}
                    </>
                  ) : (
                    <Typography className={styles.productPrice}>Out of stock</Typography>
                  )}
                </>
              )}
              {!outOfStock && (
                <Box
                  className={styles.productTypeContainer}
                  sx={productData.deleted_at && { marginTop: '20px' }}
                >
                  <Typography className={styles.productTypeTitle}>
                    Carbon footprint (CO2):
                  </Typography>
                  <Image src="/images/market-place/zero-carbon.svg" width={20} />
                </Box>
              )}
              {productData.deleted_at ? (
                <Typography className={styles.notExist}>Unavailable</Typography>
              ) : !isSeller ? (
                <>
                  {isInCart ? (
                    <Box className={styles.productCardCountContainer}>
                      <Box style={{ display: 'flex' }} className={styles.productCardCount}>
                        <ItemCounter
                          id={productData.id}
                          priceId={activeSize.id}
                          unitName={activeSize.unit?.name}
                          initialCount={activeSize.userCart?.quantity || cardInfo}
                          price={activeSize}
                          onUpdate={getProduct}
                        />
                        <Typography className={styles.productAvailableCount}>
                          {activeSize?.quantity_all} available
                        </Typography>
                      </Box>
                      <Typography className={styles.inCartText}>Product already in cart</Typography>
                    </Box>
                  ) : (
                    <Box className={styles.productCountContainer}>
                      <Box className={styles.productChangeCountBlock}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                            height: 39,
                          }}
                          onClick={
                            outOfStock ||
                            countValue === activeSize.min_order_quantity ||
                            !availableToBuy
                              ? () => {}
                              : () => changeCountValue(countValue - 1)
                          }
                        >
                          <Image
                            src={
                              outOfStock || !availableToBuy
                                ? '/images/market-place/minus-disabled.svg'
                                : '/images/market-place/minus.svg'
                            }
                            width={14}
                          />
                        </div>
                        <TextField
                          className={styles.productCountInput}
                          style={{ width: `${countValue.toString().length + 4}ch` }}
                          value={inputValue}
                          type="number"
                          onChange={handleInputChange}
                          onKeyPress={handleKeyPress}
                          onBlur={handleBlurOrEnter}
                          disabled={outOfStock || !availableToBuy}
                        />
                        <div
                          style={{ cursor: 'pointer' }}
                          onClick={
                            outOfStock || !availableToBuy
                              ? () => {}
                              : () => changeCountValue(countValue + 1)
                          }
                        >
                          <Image
                            src={
                              outOfStock || !availableToBuy
                                ? '/images/market-place/plus-disabled.svg'
                                : '/images/market-place/plus.svg'
                            }
                            width={14}
                          />
                        </div>
                      </Box>
                      {!outOfStock ? (
                        <Typography className={styles.productAvailableCount}>
                          {activeSize?.quantity_all} available
                        </Typography>
                      ) : (
                        <Typography className={styles.productOutStockText}>Out of stock</Typography>
                      )}
                    </Box>
                  )}
                  <Box className={styles.productButtonGroups}>
                    {isInCart ? (
                      <Button
                        type="default"
                        onClick={() => navigate('/dashboard/cart')}
                        className={styles.productButtonOutline}
                      >
                        <Image src="/images/market-place/cart.svg" width={20.25} />
                        <Typography className={styles.productButtonText}>Go to Cart</Typography>
                      </Button>
                    ) : (
                      <>
                        <ButtonBase
                          type="button"
                          disabled={outOfStock || !availableToBuy}
                          className={[
                            styles.productButton,
                            outOfStock || !availableToBuy ? styles.productButtonDisabled : '',
                          ]}
                          onClick={(e) => addToCart(e, true)}
                        >
                          <Typography className={styles.productButtonText}>BUY NOW</Typography>
                        </ButtonBase>
                        <ButtonBase
                          type="button"
                          className={[
                            styles.productButtonOutline,
                            outOfStock || !availableToBuy ? styles.productButtonDisabled : '',
                          ]}
                          onClick={(e) => addToCart(e)}
                          disabled={outOfStock || !availableToBuy}
                        >
                          <Image src="/images/market-place/cart.svg" width={20.25} />
                          <Typography className={styles.productButtonOutlineText}>
                            Add to cart
                          </Typography>
                        </ButtonBase>
                      </>
                    )}
                  </Box>
                </>
              ) : (
                isSeller &&
                activeSize?.quantity_all > 0 &&
                !outOfStock &&
                !isInCart && (
                  <Typography className={styles.pairCount}>
                    {activeSize?.quantity_all} Available
                  </Typography>
                )
              )}
            </Box>
          </Box>
        </Box>
        <Box className={styles.tabsContainer}>
          <Hidden mdDown>
            <Tabs
              key={isSeller}
              TabIndicatorProps={{ style: { backgroundColor: '#143471' } }}
              value={selectedPage}
              variant="scrollable"
              scrollButtons={false}
              onChange={handleChangePage}
              className={styles.tabs}
              sx={tabsSx}
            >
              {TABS.map((item) => {
                if (
                  !isSeller ||
                  (isSeller && item.label !== 'About company' && item.label !== 'Delivery methods')
                ) {
                  return (
                    <Tab
                      key={item.label}
                      value={item.label}
                      label={`${item.label}`}
                      className={[
                        styles.tabItem,
                        selectedPage === item.label ? styles.activeItem : null,
                      ]}
                    />
                  );
                }
              })}
            </Tabs>
          </Hidden>
          <Hidden mdUp>
            <Select
              onChange={handleChangePageSelect}
              value={selectedPage}
              size="medium"
              className={styles.topSelect}
              sx={{ '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
            >
              {TABS.map((item) => (
                <MenuItem value={item.label} key={item.label}>
                  <span className={styles.topMenuItem}>{item.label}</span>
                </MenuItem>
              ))}
            </Select>
          </Hidden>
        </Box>
        <Box className={styles.productInformationContainer}>
          {selectedPage === 'Description' ? (
            <DescriptionTab productData={productData} />
          ) : selectedPage === 'About company' ? (
            <AboutCompanyTab company={productData.company} />
          ) : selectedPage === 'Delivery methods' ? (
            <DeliveryMethodsTab
              deliveryMethods={deliveryMethods}
              shopAddresses={activeSize?.shopAddress ?? []}
            />
          ) : selectedPage === 'Customer reviews' ? (
            /*<CustomerReviewsTab />*/
            <Box className={styles.comingSoonBlock}>
              <img src="/images/comingSoon.svg" alt="comingSoon" />
            </Box>
          ) : (
            <Box className={styles.comingSoonBlock}>
              <img src="/images/comingSoon.svg" alt="comingSoon" />
            </Box>
          )}
        </Box>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={openSuccessAlert}
          autoHideDuration={2000}
          onClose={() => setOpenSuccessAlert(false)}
        >
          <Alert
            onClose={() => setOpenSuccessAlert(false)}
            severity="success"
            sx={{ width: '100%', backgroundColor: '#118c12', color: '#ffffff' }}
          >
            {alertText}
          </Alert>
        </Snackbar>
      </Box>
      {deleteProductModal && (
        <DeleteItemModal
          title="Do you want to delete this item?"
          open={deleteProductModal}
          handleClose={() => setDeleteProductsModal(false)}
          deleteItem={deleteProduct}
        />
      )}
      <SignInUpModalWrapper open={open} closeModal={handleLoginClose} />
    </>
  );
};

export default ProductItemPage;
