import React from 'react';
import Navbar from '../navbar/index';
import { Breadcrumb } from '../breadcrumb/index';
import styles from './layout.module.scss';
import { BigCalendar } from '../bigCalendar';
import { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import PersonalAreaBox from '../personalAreaBox';
import userContext from '../../context/User/userContext';
import {
  GET_USER,
  GET_ACTIVE_USER_COMPANIES_LIST,
  LOGOUT_USER,
  GET_SHORT_COMPANY_BY_ID,
} from '../../api';
import Cookies from 'js-cookie';
import { MobileNavbarWrapper } from '../navbarMobile/NavbarMobile';
import SupportChat from '../../modules/tt-concierge';
// import 'tt-concierge/dist/index.css';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { closeAppLoader, logoutUserHandler, openAppLoader } from '../../utils/appUtils';
import PageContainer from './PageContainer';
import { useNavigate } from 'react-router';
import DashboardWidgets from '../DashboardWidgets/DashboardWidgets';
import { AdminNavbar, AppAvatar } from '../../modules/tt-navmenu';
import { createTheme } from '@mui/material';
import { ContentBlock } from 'tt-ui-kit';
import { userCanHaveCompany } from '../../utils/userConfig';
import { getAvatarPathBySize } from '../../utils/appUtils';

const theme = createTheme({
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: '#18397A',
      darker: '#18397A',
    },
    important: {
      main: '#d95e27',
      darker: '#bb5021',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
    notSelected: {
      main: '#212121',
      contrastText: '#151515',
    },
  },
  box: {
    margin: '10px',
    padding: '60px 20px',
    borderRadius: '10px',
    backgroundColor: 'white',
  },
});

const sideMenuExcludedPage = ['pricing'];
const laSalleGreenTheme = {
  headerGradient: 'linear-gradient(99.65deg, #143471 100%, #1550BD 100%)',
  sendButtonColor: '#143471',
  messageBackgroundColor: '#E3EDFF',
  supportButton: '#143471',
};

const { REACT_APP_CONCIERGE_URL, REACT_APP_CONCIERGE_SECRETS } = process.env;

export const Layout = (props) => {
  const domainValue = process.env.REACT_APP_DOMAIN;
  const pageName = window.location.pathname.split('/').pop();
  const userID = Cookies.get('userId', { domain: domainValue });
  const companyID = Cookies.get('companyId', { domain: domainValue });

  const router = useNavigate();

  const { user, selectedCompany, changeUserData, setSelectedCompany, removeSelectedCompany } =
    useContext(userContext);

  const [getUser, { data: userData }] = useLazyQuery(GET_USER, {
    variables: { id: userID },
  });
  const [getActiveCompanies, { data: companiesData }] = useLazyQuery(
    GET_ACTIVE_USER_COMPANIES_LIST
  );
  const [getSelectedCompany] = useLazyQuery(GET_SHORT_COMPANY_BY_ID);

  const [activeCompanies, setActiveCompanies] = useState(null);
  const [logoutUser] = useMutation(LOGOUT_USER);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [kyc, setKyc] = useState('');
  const [avatar, setAvatar] = useState('');
  const [email, setEmail] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [ceo, setCeo] = useState('');
  const [roleId, setRoleId] = useState('');
  const [roleName, setRoleName] = useState('');
  const [invited, setInvited] = useState('');
  const [visibleArea, setVisibleArea] = useState('mainContent');
  const [showSupportChat, setShowSupportChat] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false);
  const [userCompanies, setUserCompanies] = useState([]);
  const [isCompaniesFetch, setIsCompaniesFetch] = useState(false);
  const [isCompanyFetch, setIsCompanyFetch] = useState(false);
  const [linkProps, setLinkProps] = useState([]);

  useEffect(() => {
    if (userID) {
      openAppLoader();

      getUser({
        variables: { id: userID },
      }).finally(() => {
        setTimeout(() => closeAppLoader(), 500);
      });
    } else {
      setTimeout(() => closeAppLoader(), 500);
    }
  }, [userID, getUser]);

  useEffect(() => {
    setLinkProps([
      { href: '/Calculators', name: 'Score & Reporting' },
      { href: '/dashboard/marketplace', name: 'Marketplace' },
    ]);
    if (userCanHaveCompany(user.role_name)) {
      setLinkProps((current) => [
        ...current,
        { href: '/dashboard/companiesList', name: 'Company Profile' },
      ]);
    }
    setLinkProps((current) => [
      ...current,
      { href: '/digitalAssets', name: 'Digital Assets' },
      { href: '/events', name: 'Events' },
      { href: '/financials', name: 'Financials' },
      { href: '/content', name: 'Content' },
    ]);
  }, [user]);

  useEffect(() => {
    if (userData?.user && !user?.id) {
      changeUserData(userData.user);
      setFirstName(userData.user.first_name);
      setLastName(userData.user.last_name);
      setAvatar(userData.user.avatar);
      setEmail(userData.user.email);
      setCreatedAt(userData.user.created_at);
      setCeo(userData.user.ceo_of);
      setKyc(userData.user.kyc);
      setRoleId(userData.user.role_id);
      setRoleName(userData.user.role_name);
      setInvited(userData.user.invited_by_name);
      setShowNavbar(!!userData?.user?.roles?.find((role) => role.name === 'admin'));
    }
  }, [userData, user, changeUserData]);

  useEffect(() => {
    if (user?.id) {
      getActiveCompanies().then();
      if (companiesData?.showActiveUserCompanies?.length) {
        setActiveCompanies(companiesData?.showActiveUserCompanies);
      }
    }
  }, [user, getActiveCompanies, companiesData]);

  const onChangeCompany = (companyInfo) => {
    setSelectedCompany(companyInfo);
  };

  const onChangeToPersonal = () => {
    removeSelectedCompany();
  };

  const getUserCompanies = () => {
    (async () => {
      const companyValue = await getActiveCompanies();

      if (companyValue?.data?.showActiveUserCompanies?.length) {
        setUserCompanies(companyValue?.data.showActiveUserCompanies);
      } else {
        setUserCompanies([]);
      }
    })();
  };

  const getUserSelectedCompany = () => {
    (async () => {
      const companyValue = await getSelectedCompany({
        variables: { id: companyID },
      });

      if (companyValue.data?.showCompanyById) {
        onChangeCompany(companyValue.data.showCompanyById);
      } else {
        onChangeCompany(null);
      }
    })();
  };

  useEffect(() => {
    if (user.id && !isCompaniesFetch) {
      getUserCompanies();
      setIsCompaniesFetch(true);
    }
  }, [user]);

  useEffect(() => {
    if (!selectedCompany.isCompanySelected && companyID && user.id && !isCompanyFetch) {
      getUserSelectedCompany();
      setIsCompanyFetch(true);
    } else if (selectedCompany.isCompanySelected && !companyID) {
      removeSelectedCompany();
    }
  }, [companyID, selectedCompany, user]);

  const breadCrumbs = useBreadcrumbs(props.routes);
  let breadCrumbPathName = breadCrumbs[breadCrumbs.length - 1].match.pathname;
  const breadCrumbPath = breadCrumbs[breadCrumbs.length - 1].match.pathname.split('/');
  let breadCrumbNewPath = [];
  let breadCrumbsData = {};

  for (let i = 0; i < breadCrumbPath.length; i++) {
    if (breadCrumbPath[i] && breadCrumbPath[i] !== 'dashboard' && !/\d/.test(breadCrumbPath[i])) {
      if (breadCrumbPath[i].includes('?')) {
        breadCrumbNewPath.push(`/${breadCrumbPath[i].split('?')[0]}`);
      } else {
        breadCrumbNewPath.push(`/${breadCrumbPath[i]}`);
      }
    }
  }

  if (/\d/.test(breadCrumbPathName)) {
    props.routes.map((item) => {
      const path = item.path.split('/');
      let newPath = [];
      for (let i = 0; i < path.length; i++) {
        if (breadCrumbPath[i] && !path[i].includes(':')) {
          newPath.push(`/${path[i]}`);
        }
      }
      if (newPath.toString() === breadCrumbNewPath.toString()) {
        breadCrumbsData = {
          href: item.path,
          name: item.name,
        };
      }
    });
  } else {
    props.routes.map((item) => {
      if (item.path === breadCrumbNewPath.toString().replace(',', '')) {
        breadCrumbsData = {
          href: item.path,
          name: item.name,
        };
      }
    });
  }

  const openOptionProfile = () => {
    router(`/dashboard/profile/${user?.id}`);
  };

  const logOut = () => {
    logoutUserHandler(logoutUser);
  };

  const handleScroll = (e) => {
    const scrollPosition = e.target.scrollTop;
    if (window.location.pathname === '/dashboard/cart') {
      if (scrollPosition > 50) {
        props.setScrollDown(true);
      } else {
        props.setScrollDown(false);
      }
    }
  };

  return (
    <PageContainer>
      <SupportChat
        them={laSalleGreenTheme}
        showSupportChat={showSupportChat}
        setShowSupportChat={setShowSupportChat}
        companyName="TT Concierge"
        apiUrl={REACT_APP_CONCIERGE_URL} // API url
        apiSecret={REACT_APP_CONCIERGE_SECRETS} // API Authorization token
        supportMail="support@transparenterra.com"
        user={user}
      />
      <div
        className={styles.content}
        id="content-layout"
        onScroll={handleScroll}
        ref={props.divRef}
      >
        <div className={styles.headerWrapper} id="mainHeader">
          <AdminNavbar
            showNavbar={showNavbar}
            theme={theme}
            baseUrl={process.env.REACT_APP_TT || ''}
            baseFceUrl={process.env.REACT_APP_DIGITAL_ASSETS || ''}
            mode="tt"
          />

          <Navbar
            backProps={() => setVisibleArea('mainContent')}
            avaProps={
              user && (
                <AppAvatar
                  user={user}
                  selectedCompany={selectedCompany}
                  arrowButton={1}
                  nav={2}
                  selectSettings={() => router('/dashboard/settings')}
                  selectHelp={() => router('/dashboard/help')}
                  userName={
                    selectedCompany.isCompanySelected
                      ? selectedCompany.selectedCompany.company_name
                      : `${firstName} ${lastName}`
                  }
                  src={
                    selectedCompany.isCompanySelected
                      ? getAvatarPathBySize(selectedCompany.selectedCompany.logos, 'smallPath')
                      : avatar
                  }
                  openOptions={1}
                  selectProfile={openOptionProfile}
                  logOut={logOut}
                  logoutUserHandler={logOut}
                  userCompanies={userCanHaveCompany(user.role_name) ? userCompanies : []}
                  selectCompany={onChangeCompany}
                  selectToPersonalProfile={onChangeToPersonal}
                  mainTTUrl={process.env.REACT_APP_TT || ''}
                  marketTTUrl={''}
                  digitalAssetsTTUrl={process.env.REACT_APP_DIGITAL_ASSETS || ''}
                  mode="market"
                />
              )
            }
            logOut={logOut}
            setOpenSupportChat={setShowSupportChat}
            marketTTUrl={''}
            redirectDomain={process.env.REACT_APP_TT || ''}
            digitalAssetsTTUrl={process.env.REACT_APP_DIGITAL_ASSETS || ''}
            qaTTUrl={process.env.REACT_APP_QA}
          />
          <div className={styles.breadcrumb}>
            <Breadcrumb
              data={[{ href: '/dashboard/feed', name: 'TransparenTerra' }, { ...breadCrumbsData }]}
            />
          </div>
        </div>
        <div className={styles.pageContentWrapper}>
          <div className={styles.pageContent}>
            {!sideMenuExcludedPage.includes(pageName) && (
              <div className={styles.leftColumn} style={{ top: showNavbar ? 195 : 126 }}>
                {activeCompanies ? (
                  <PersonalAreaBox
                    avaProps={
                      <AppAvatar
                        user={user}
                        selectedCompany={selectedCompany}
                        mainTTUrl={process.env.REACT_APP_TT || ''}
                        marketTTUrl={''}
                        userName={
                          selectedCompany.isCompanySelected
                            ? selectedCompany.selectedCompany.company_name
                            : `${firstName} ${lastName}`
                        }
                        src={
                          selectedCompany.isCompanySelected
                            ? getAvatarPathBySize(
                                selectedCompany.selectedCompany.logos,
                                'mediumPath'
                              )
                            : avatar
                        }
                        role={selectedCompany.isCompanySelected ? '' : roleName}
                        nav={0}
                        onClickShowProfile
                        mode="market"
                      />
                    }
                    firstNameProps={{
                      children: selectedCompany.isCompanySelected
                        ? selectedCompany.selectedCompany.company_name
                        : firstName,
                    }}
                    lastNameProps={{ children: selectedCompany.isCompanySelected ? '' : lastName }}
                    inviteProps={{ children: selectedCompany.isCompanySelected ? '' : invited }}
                    ceoProps={{ children: selectedCompany.isCompanySelected ? '' : ceo }}
                    kycProps={{ children: selectedCompany.isCompanySelected ? '' : kyc }}
                    mode="market"
                  />
                ) : (
                  <PersonalAreaBox
                    avaProps={
                      <AppAvatar
                        user={user}
                        selectedCompany={selectedCompany}
                        mainTTUrl={process.env.REACT_APP_TT || ''}
                        marketTTUrl={''}
                        userName={
                          selectedCompany.isCompanySelected
                            ? selectedCompany.selectedCompany.company_name
                            : `${firstName} ${lastName}`
                        }
                        src={
                          selectedCompany.isCompanySelected
                            ? getAvatarPathBySize(
                                selectedCompany.selectedCompany.logos,
                                'mediumPath'
                              )
                            : avatar
                        }
                        role={selectedCompany.isCompanySelected ? '' : roleName}
                        nav={0}
                        onClickShowProfile
                        mode="market"
                      />
                    }
                    firstNameProps={{
                      children: selectedCompany.isCompanySelected
                        ? selectedCompany.selectedCompany.company_name
                        : firstName,
                    }}
                    lastNameProps={{ children: selectedCompany.isCompanySelected ? '' : lastName }}
                    inviteProps={{ children: selectedCompany.isCompanySelected ? '' : invited }}
                    ceoProps={{ children: selectedCompany.isCompanySelected ? '' : ceo }}
                    kycProps={{ children: selectedCompany.isCompanySelected ? '' : kyc }}
                    mode="market"
                  />
                )}
              </div>
            )}

            <ContentBlock>
              <div className={styles.wrapperContent}>
                {visibleArea === 'mainContent' && props.children}
                {visibleArea === 'bigCalendar' && (
                  <BigCalendar onClose={() => setVisibleArea('mainContent')}></BigCalendar>
                )}
              </div>
            </ContentBlock>

            {!sideMenuExcludedPage.includes(pageName) && (
              <DashboardWidgets user={user} kyc={kyc} showNavbar={showNavbar} />
            )}

            <div className={styles.mobileNavigation}>
              <MobileNavbarWrapper />
            </div>
          </div>
        </div>
      </div>
    </PageContainer>
  );
};
